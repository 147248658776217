/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v4.0.0
  * Copyright 2021 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*------------------------------------
  Default Styles
------------------------------------*/
a {
  text-decoration: none;
}

:focus,
a:focus,
button:focus {
  outline-color: rgba(55, 125, 255, 0.5);
}

figure {
  margin-bottom: 0;
}

dl {
  margin-bottom: 0;
}

dt {
  color: #1e2022;
}

dd {
  margin-bottom: 0.75rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

::selection {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.bg-primary ::-moz-selection {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-primary ::selection {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Animation
------------------------------------*/
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
          animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
          animation-name: fadeOutDown;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
          animation-name: slideInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
          animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
          animation-name: fadeOutRight;
}

/*------------------------------------
  Accordion
------------------------------------*/
.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button {
  color: #21325b;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
}

@media (max-width: 575.98px) {
  .accordion-button {
    padding: 0.5rem 0.83333rem;
  }
}

.accordion-body {
  padding-top: 0;
}

@media (max-width: 575.98px) {
  .accordion-button,
  .accordion-body {
    padding: 0.5rem 0.83333rem;
  }
}

.accordion-flush .accordion-button,
.accordion-flush .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion-lg .accordion-button {
  font-size: 1.125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.accordion-lg .accordion-body {
  padding-bottom: 1.5rem;
}

.accordion-btn-icon-start .accordion-button::before {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2321325b'%3e%3cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-btn-icon-start .accordion-button::before {
    transition: none;
  }
}

.accordion-btn-icon-start .accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23377dff'%3e%3cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.accordion-btn-icon-start .accordion-button::after {
  display: none;
}

/*------------------------------------
  Alert Styles
------------------------------------*/
.alert-primary {
  color: #fff;
  background-color: #377dff;
  border-color: #377dff;
}

.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  color: #fff;
  background-color: #71869d;
  border-color: #71869d;
}

.alert-secondary .alert-link {
  color: #fff;
}

.alert-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.alert-success .alert-link {
  color: #fff;
}

.alert-info {
  color: #fff;
  background-color: #09a5be;
  border-color: #09a5be;
}

.alert-info .alert-link {
  color: #fff;
}

.alert-warning {
  color: #000;
  background-color: #f5ca99;
  border-color: #f5ca99;
}

.alert-warning .alert-link {
  color: #000;
}

.alert-danger {
  color: #fff;
  background-color: #ed4c78;
  border-color: #ed4c78;
}

.alert-danger .alert-link {
  color: #fff;
}

.alert-light {
  color: #000;
  background-color: #f7faff;
  border-color: #f7faff;
}

.alert-light .alert-link {
  color: #000;
}

.alert-dark {
  color: #fff;
  background-color: #21325b;
  border-color: #21325b;
}

.alert-dark .alert-link {
  color: #fff;
}

.alert-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.15);
}

.alert-soft-primary .alert-link {
  color: #377dff;
}

.alert-soft-primary .alert-link:hover {
  color: #1366ff;
}

.alert-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.15);
}

.alert-soft-secondary .alert-link {
  color: #71869d;
}

.alert-soft-secondary .alert-link:hover {
  color: #60748b;
}

.alert-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.15);
}

.alert-soft-success .alert-link {
  color: #00c9a7;
}

.alert-soft-success .alert-link:hover {
  color: #00a589;
}

.alert-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.15);
}

.alert-soft-info .alert-link {
  color: #09a5be;
}

.alert-soft-info .alert-link:hover {
  color: #07879c;
}

.alert-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.15);
}

.alert-soft-warning .alert-link {
  color: #f5ca99;
}

.alert-soft-warning .alert-link:hover {
  color: #f2b978;
}

.alert-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.15);
}

.alert-soft-danger .alert-link {
  color: #ed4c78;
}

.alert-soft-danger .alert-link:hover {
  color: #ea2c60;
}

.alert-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.15);
}

.alert-soft-light .alert-link {
  color: #f7faff;
}

.alert-soft-light .alert-link:hover {
  color: #d3e4ff;
}

.alert-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.15);
}

.alert-soft-dark .alert-link {
  color: #21325b;
}

.alert-soft-dark .alert-link:hover {
  color: #172441;
}

.alert-white {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  background-color: #fff;
}

/*------------------------------------
  Avatar
------------------------------------*/
.avatar {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.5rem;
}

.avatar:not(img) {
  background-color: #fff;
}

.avatar-img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.5rem;
}

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.avatar-circle {
  border-radius: 50%;
}

.avatar-circle .avatar,
.avatar-circle .avatar-initials,
.avatar-circle .avatar-img {
  border-radius: 50%;
}

.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

/*------------------------------------
  Avatar Group
------------------------------------*/
.avatar-group {
  display: -ms-flexbox;
  display: flex;
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar-circle .avatar-initials {
  border-radius: 50%;
}

.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials,
.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials,
.avatar-group .avatar .avatar-img,
.avatar-group .avatar .avatar-initials {
  border: 2px solid #fff;
}

.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
  border: 5px solid #fff;
}

.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
  border: 7px solid #fff;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.6125rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.875rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1.5rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Group Sizes
------------------------------------*/
.avatar-group .avatar {
  width: 3.125rem;
  height: 3.125rem;
}

.avatar-group .avatar .avatar-initials {
  font-size: 1rem;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group-xs .avatar {
  width: 1.75rem;
  height: 1.75rem;
}

.avatar-group-xs .avatar .avatar-initials {
  font-size: 0.75rem;
}

.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}

.avatar-group-sm .avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-group-sm .avatar .avatar-initials {
  font-size: 0.8125rem;
}

.avatar-group-sm .avatar + .avatar {
  margin-left: -0.875rem;
}

.avatar-group-lg .avatar {
  width: 4.25rem;
  height: 4.25rem;
}

.avatar-group-lg .avatar .avatar-initials {
  font-size: 1.25rem;
}

.avatar-group-lg .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xl .avatar {
  width: 5.625rem;
  height: 5.625rem;
}

.avatar-group-xl .avatar .avatar-initials {
  font-size: 2rem;
}

.avatar-group-xl .avatar + .avatar {
  margin-left: -1.5rem;
}

.avatar-group-xxl .avatar {
  width: 7rem;
  height: 7rem;
}

.avatar-group-xxl .avatar .avatar-initials {
  font-size: 3rem;
}

.avatar-group-xxl .avatar + .avatar {
  margin-left: -2rem;
}

/*------------------------------------
  Avatar Sizes
------------------------------------*/
.avatar.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}

.avatar.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xss {
  width: 1rem;
  height: 1rem;
}

.avatar-xss .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
}

.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.11719rem;
  right: -0.11719rem;
}

.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
}

.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}

.avatar-xs.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -0.11719rem;
  right: -0.11719rem;
}

.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -0.325rem;
  right: -0.325rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-sm .avatar-initials {
  font-size: 0.8125rem;
}

.avatar-sm.avatar-circle .avatar-status {
  bottom: -0.21875rem;
  right: -0.21875rem;
}

.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -0.09375rem;
  right: -0.09375rem;
}

.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -0.40625rem;
  right: -0.40625rem;
}

.avatar-lg {
  width: 4.25rem;
  height: 4.25rem;
}

.avatar-lg .avatar-initials {
  font-size: 1.25rem;
}

.avatar-lg.avatar-circle .avatar-status {
  bottom: -0.13125rem;
  right: -0.13125rem;
}

.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 0.09375rem;
  right: 0.09375rem;
}

.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -0.20312rem;
  right: -0.20312rem;
}

.avatar-xl {
  width: 5.625rem;
  height: 5.625rem;
}

.avatar-xl .avatar-initials {
  font-size: 2rem;
}

.avatar-xl.avatar-circle .avatar-status {
  bottom: 0.16406rem;
  right: 0.16406rem;
}

.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 0.23438rem;
  right: 0.23438rem;
}

.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 0.13542rem;
  right: 0.13542rem;
}

.avatar-xxl {
  width: 7rem;
  height: 7rem;
}

.avatar-xxl .avatar-initials {
  font-size: 3rem;
}

.avatar-xxl.avatar-circle .avatar-status {
  bottom: 0.75rem;
  right: 0.75rem;
}

.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 0.875rem;
  right: 0.875rem;
}

.avatar-xxl.avatar-circle .avatar-lg-status {
  bottom: 0.27083rem;
  right: 0.27083rem;
}

/*------------------------------------
  Avatar Status
------------------------------------*/
.avatar-status {
  position: absolute;
  bottom: -0.525rem;
  right: -0.525rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 1.3125rem;
  height: 1.3125rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}

.avatar-sm-status {
  bottom: -0.2625rem;
  right: -0.2625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  font-size: 0.5rem;
}

.avatar-lg-status {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.75rem;
}

/*------------------------------------
  Avatar Styles
------------------------------------*/
.avatar-primary .avatar-initials {
  color: #fff;
  background-color: #377dff;
}

.avatar-status-primary {
  color: #fff;
  background-color: #377dff;
}

.avatar-secondary .avatar-initials {
  color: #fff;
  background-color: #71869d;
}

.avatar-status-secondary {
  color: #fff;
  background-color: #71869d;
}

.avatar-success .avatar-initials {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-status-success {
  color: #fff;
  background-color: #00c9a7;
}

.avatar-info .avatar-initials {
  color: #fff;
  background-color: #09a5be;
}

.avatar-status-info {
  color: #fff;
  background-color: #09a5be;
}

.avatar-warning .avatar-initials {
  color: #000;
  background-color: #f5ca99;
}

.avatar-status-warning {
  color: #000;
  background-color: #f5ca99;
}

.avatar-danger .avatar-initials {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-status-danger {
  color: #fff;
  background-color: #ed4c78;
}

.avatar-light .avatar-initials {
  color: #000;
  background-color: #f7faff;
}

.avatar-status-light {
  color: #000;
  background-color: #f7faff;
}

.avatar-dark .avatar-initials {
  color: #fff;
  background-color: #21325b;
}

.avatar-status-dark {
  color: #fff;
  background-color: #21325b;
}

.avatar-soft-primary .avatar-initials {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.avatar-status-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.avatar-soft-secondary .avatar-initials {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-status-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.avatar-soft-success .avatar-initials {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-status-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.avatar-soft-info .avatar-initials {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.avatar-status-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.avatar-soft-warning .avatar-initials {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-status-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.avatar-soft-danger .avatar-initials {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-status-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.avatar-soft-light .avatar-initials {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.avatar-status-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.avatar-soft-dark .avatar-initials {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.avatar-status-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Avatar Ratio
------------------------------------*/
.avatar.avatar-4x3 {
  width: 4.16667rem;
  height: auto;
  border-radius: 0;
}

.avatar.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xss.avatar-4x3 {
  width: 1.33333rem;
  height: 1rem;
  border-radius: 0;
}

.avatar-xss.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xs.avatar-4x3 {
  width: 2.33333rem;
  height: auto;
  border-radius: 0;
}

.avatar-xs.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-sm.avatar-4x3 {
  width: 3.33333rem;
  height: auto;
  border-radius: 0;
}

.avatar-sm.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-lg.avatar-4x3 {
  width: 5.66667rem;
  height: auto;
  border-radius: 0;
}

.avatar-lg.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xl.avatar-4x3 {
  width: 7.5rem;
  height: auto;
  border-radius: 0;
}

.avatar-xl.avatar-4x3 .avatar-img {
  height: inherit;
}

.avatar-xxl.avatar-4x3 {
  width: 9.33333rem;
  height: auto;
  border-radius: 0;
}

.avatar-xxl.avatar-4x3 .avatar-img {
  height: inherit;
}

/*------------------------------------
  Badge
------------------------------------*/
.badge {
  line-height: normal;
}

/*------------------------------------
  Banner
------------------------------------*/
.banner-half-middle-x {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  z-index: -1;
}

.banner-half-middle-x::before {
  position: absolute;
  top: 50%;
  right: 100%;
  width: 80%;
  height: 80%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.075) 50%, rgba(255, 255, 255, 0));
  content: "";
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*------------------------------------
  Blockquote
------------------------------------*/
.blockquote {
  color: #1e2022;
}

.blockquote-left-border {
  color: #677788;
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
  padding-left: 0.75rem;
}

.blockquote-footer {
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.blockquote-footer::before {
  content: '';
}

.blockquote-footer-source {
  display: block;
  color: #677788;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.25rem;
}

.blockquote-light {
  color: #fff;
}

.blockquote-light .blockquote-footer-source {
  color: rgba(255, 255, 255, 0.7);
}

.blockquote-sm {
  font-size: 1rem;
}

.blockquote-lg {
  font-size: 1.75rem;
}

/*------------------------------------
  Brand
------------------------------------*/
.brand {
  width: 100%;
  max-width: 7.5rem;
}

/*------------------------------------
  Breadcrumb
------------------------------------*/
.breadcrumb .breadcrumb-item {
  color: #1e2022;
}

.breadcrumb-light .breadcrumb-item {
  color: #fff;
}

.breadcrumb-light .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb-light .breadcrumb-link {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb-light .breadcrumb-link:hover {
  color: #fff;
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn-link {
  font-weight: 400;
}

.btn-link:focus {
  box-shadow: none;
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: none;
}

.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
}

.btn-transition {
  transition: all 0.2s ease-in-out;
}

.btn-transition:hover, .btn-transition:focus {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-primary, .btn-primary:hover, .btn-primary:focus {
  box-shadow: 0 4px 11px rgba(55, 125, 255, 0.35);
}

.btn-check:focus + .btn-secondary, .btn-secondary:hover, .btn-secondary:focus {
  box-shadow: 0 4px 11px rgba(113, 134, 157, 0.35);
}

.btn-check:focus + .btn-success, .btn-success:hover, .btn-success:focus {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-check:focus + .btn-info, .btn-info:hover, .btn-info:focus {
  box-shadow: 0 4px 11px rgba(9, 165, 190, 0.35);
}

.btn-check:focus + .btn-warning, .btn-warning:hover, .btn-warning:focus {
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.35);
}

.btn-check:focus + .btn-danger, .btn-danger:hover, .btn-danger:focus {
  box-shadow: 0 4px 11px rgba(237, 76, 120, 0.35);
}

.btn-check:focus + .btn-light, .btn-light:hover, .btn-light:focus {
  box-shadow: 0 4px 11px rgba(247, 250, 255, 0.35);
}

.btn-check:focus + .btn-dark, .btn-dark:hover, .btn-dark:focus {
  box-shadow: 0 4px 11px rgba(33, 50, 91, 0.35);
}

.btn-toggle .btn-toggle-default {
  display: inline-block;
}

.btn-toggle .btn-toggle-toggled {
  display: none;
}

.btn-toggle.toggled .btn-toggle-default {
  display: none;
}

.btn-toggle.toggled .btn-toggle-toggled {
  display: inline-block;
}

/*------------------------------------
  Custom Buttons
------------------------------------*/
.btn-white {
  background-color: #fff;
  border-color: rgba(33, 50, 91, 0.1);
}

.btn-white.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-white, .btn-white:hover, .btn-white:focus {
  color: #1366ff;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

.btn-check:focus + .btn-white.dropdown-toggle::after, .btn-white:hover.dropdown-toggle::after, .btn-white:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-white.disabled, .btn-white:disabled {
  color: #bdc5d1;
  background-color: rgba(231, 234, 243, 0.5);
}

.btn-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

/*------------------------------------
  Ghost Buttons Styles
------------------------------------*/
.btn-ghost-primary {
  color: #377dff;
  background-color: transparent;
}

.btn-ghost-primary:hover,
.btn-check:focus + .btn-ghost-primary, .btn-ghost-primary:focus,
.btn-check:checked + .btn-ghost-primary,
.btn-check:active + .btn-ghost-primary, .btn-ghost-primary:active, .btn-ghost-primary.active,
.show > .btn-ghost-primary.dropdown-toggle {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-ghost-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus, .btn-ghost-primary.btn-no-focus:hover, .btn-ghost-primary.btn-no-focus.focus, .btn-ghost-primary.btn-no-focus:focus {
  color: #377dff;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus:hover, .btn-ghost-primary.btn-no-focus:hover:hover, .btn-ghost-primary.btn-no-focus.focus:hover, .btn-ghost-primary.btn-no-focus:focus:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus.btn-transition, .btn-ghost-primary.btn-no-focus.btn-transition:hover, .btn-ghost-primary.btn-no-focus.btn-transition.focus, .btn-ghost-primary.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-primary.btn-no-focus.btn-transition:hover, .btn-ghost-primary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-primary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-primary.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-secondary {
  color: #71869d;
  background-color: transparent;
}

.btn-ghost-secondary:hover,
.btn-check:focus + .btn-ghost-secondary, .btn-ghost-secondary:focus,
.btn-check:checked + .btn-ghost-secondary,
.btn-check:active + .btn-ghost-secondary, .btn-ghost-secondary:active, .btn-ghost-secondary.active,
.show > .btn-ghost-secondary.dropdown-toggle {
  color: #71869d;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-ghost-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus, .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus.focus, .btn-ghost-secondary.btn-no-focus:focus {
  color: #71869d;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus:hover:hover, .btn-ghost-secondary.btn-no-focus.focus:hover, .btn-ghost-secondary.btn-no-focus:focus:hover {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition, .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus, .btn-ghost-secondary.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-success {
  color: #00c9a7;
  background-color: transparent;
}

.btn-ghost-success:hover,
.btn-check:focus + .btn-ghost-success, .btn-ghost-success:focus,
.btn-check:checked + .btn-ghost-success,
.btn-check:active + .btn-ghost-success, .btn-ghost-success:active, .btn-ghost-success.active,
.show > .btn-ghost-success.dropdown-toggle {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.btn-ghost-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-success.btn-no-focus, .btn-ghost-success.btn-no-focus:hover, .btn-ghost-success.btn-no-focus.focus, .btn-ghost-success.btn-no-focus:focus {
  color: #00c9a7;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-success.btn-no-focus:hover, .btn-ghost-success.btn-no-focus:hover:hover, .btn-ghost-success.btn-no-focus.focus:hover, .btn-ghost-success.btn-no-focus:focus:hover {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-success.btn-no-focus.btn-transition, .btn-ghost-success.btn-no-focus.btn-transition:hover, .btn-ghost-success.btn-no-focus.btn-transition.focus, .btn-ghost-success.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-success.btn-no-focus.btn-transition:hover, .btn-ghost-success.btn-no-focus.btn-transition:hover:hover, .btn-ghost-success.btn-no-focus.btn-transition.focus:hover, .btn-ghost-success.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-info {
  color: #09a5be;
  background-color: transparent;
}

.btn-ghost-info:hover,
.btn-check:focus + .btn-ghost-info, .btn-ghost-info:focus,
.btn-check:checked + .btn-ghost-info,
.btn-check:active + .btn-ghost-info, .btn-ghost-info:active, .btn-ghost-info.active,
.show > .btn-ghost-info.dropdown-toggle {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.btn-ghost-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-info.btn-no-focus, .btn-ghost-info.btn-no-focus:hover, .btn-ghost-info.btn-no-focus.focus, .btn-ghost-info.btn-no-focus:focus {
  color: #09a5be;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-info.btn-no-focus:hover, .btn-ghost-info.btn-no-focus:hover:hover, .btn-ghost-info.btn-no-focus.focus:hover, .btn-ghost-info.btn-no-focus:focus:hover {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-info.btn-no-focus.btn-transition, .btn-ghost-info.btn-no-focus.btn-transition:hover, .btn-ghost-info.btn-no-focus.btn-transition.focus, .btn-ghost-info.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-info.btn-no-focus.btn-transition:hover, .btn-ghost-info.btn-no-focus.btn-transition:hover:hover, .btn-ghost-info.btn-no-focus.btn-transition.focus:hover, .btn-ghost-info.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-warning {
  color: #f5ca99;
  background-color: transparent;
}

.btn-ghost-warning:hover,
.btn-check:focus + .btn-ghost-warning, .btn-ghost-warning:focus,
.btn-check:checked + .btn-ghost-warning,
.btn-check:active + .btn-ghost-warning, .btn-ghost-warning:active, .btn-ghost-warning.active,
.show > .btn-ghost-warning.dropdown-toggle {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.btn-ghost-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus, .btn-ghost-warning.btn-no-focus:hover, .btn-ghost-warning.btn-no-focus.focus, .btn-ghost-warning.btn-no-focus:focus {
  color: #f5ca99;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus:hover, .btn-ghost-warning.btn-no-focus:hover:hover, .btn-ghost-warning.btn-no-focus.focus:hover, .btn-ghost-warning.btn-no-focus:focus:hover {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus.btn-transition, .btn-ghost-warning.btn-no-focus.btn-transition:hover, .btn-ghost-warning.btn-no-focus.btn-transition.focus, .btn-ghost-warning.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-warning.btn-no-focus.btn-transition:hover, .btn-ghost-warning.btn-no-focus.btn-transition:hover:hover, .btn-ghost-warning.btn-no-focus.btn-transition.focus:hover, .btn-ghost-warning.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-danger {
  color: #ed4c78;
  background-color: transparent;
}

.btn-ghost-danger:hover,
.btn-check:focus + .btn-ghost-danger, .btn-ghost-danger:focus,
.btn-check:checked + .btn-ghost-danger,
.btn-check:active + .btn-ghost-danger, .btn-ghost-danger:active, .btn-ghost-danger.active,
.show > .btn-ghost-danger.dropdown-toggle {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.btn-ghost-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus, .btn-ghost-danger.btn-no-focus:hover, .btn-ghost-danger.btn-no-focus.focus, .btn-ghost-danger.btn-no-focus:focus {
  color: #ed4c78;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus:hover, .btn-ghost-danger.btn-no-focus:hover:hover, .btn-ghost-danger.btn-no-focus.focus:hover, .btn-ghost-danger.btn-no-focus:focus:hover {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus.btn-transition, .btn-ghost-danger.btn-no-focus.btn-transition:hover, .btn-ghost-danger.btn-no-focus.btn-transition.focus, .btn-ghost-danger.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-danger.btn-no-focus.btn-transition:hover, .btn-ghost-danger.btn-no-focus.btn-transition:hover:hover, .btn-ghost-danger.btn-no-focus.btn-transition.focus:hover, .btn-ghost-danger.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-light {
  color: #f7faff;
  background-color: transparent;
}

.btn-ghost-light:hover,
.btn-check:focus + .btn-ghost-light, .btn-ghost-light:focus,
.btn-check:checked + .btn-ghost-light,
.btn-check:active + .btn-ghost-light, .btn-ghost-light:active, .btn-ghost-light.active,
.show > .btn-ghost-light.dropdown-toggle {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.btn-ghost-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-light.btn-no-focus, .btn-ghost-light.btn-no-focus:hover, .btn-ghost-light.btn-no-focus.focus, .btn-ghost-light.btn-no-focus:focus {
  color: #f7faff;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-light.btn-no-focus:hover, .btn-ghost-light.btn-no-focus:hover:hover, .btn-ghost-light.btn-no-focus.focus:hover, .btn-ghost-light.btn-no-focus:focus:hover {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-light.btn-no-focus.btn-transition, .btn-ghost-light.btn-no-focus.btn-transition:hover, .btn-ghost-light.btn-no-focus.btn-transition.focus, .btn-ghost-light.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-light.btn-no-focus.btn-transition:hover, .btn-ghost-light.btn-no-focus.btn-transition:hover:hover, .btn-ghost-light.btn-no-focus.btn-transition.focus:hover, .btn-ghost-light.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-ghost-dark {
  color: #21325b;
  background-color: transparent;
}

.btn-ghost-dark:hover,
.btn-check:focus + .btn-ghost-dark, .btn-ghost-dark:focus,
.btn-check:checked + .btn-ghost-dark,
.btn-check:active + .btn-ghost-dark, .btn-ghost-dark:active, .btn-ghost-dark.active,
.show > .btn-ghost-dark.dropdown-toggle {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.btn-ghost-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus, .btn-ghost-dark.btn-no-focus:hover, .btn-ghost-dark.btn-no-focus.focus, .btn-ghost-dark.btn-no-focus:focus {
  color: #21325b;
  background-color: unset;
  box-shadow: none;
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus:hover, .btn-ghost-dark.btn-no-focus:hover:hover, .btn-ghost-dark.btn-no-focus.focus:hover, .btn-ghost-dark.btn-no-focus:focus:hover {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus.btn-transition, .btn-ghost-dark.btn-no-focus.btn-transition:hover, .btn-ghost-dark.btn-no-focus.btn-transition.focus, .btn-ghost-dark.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-dark.btn-no-focus.btn-transition:hover, .btn-ghost-dark.btn-no-focus.btn-transition:hover:hover, .btn-ghost-dark.btn-no-focus.btn-transition.focus:hover, .btn-ghost-dark.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

.btn-check:focus + .btn-ghost-secondary, .btn-ghost-secondary:hover, .btn-ghost-secondary:focus {
  color: #377dff;
}

.btn-check:focus + .btn-ghost-secondary.dropdown-toggle::after, .btn-ghost-secondary:hover.dropdown-toggle::after, .btn-ghost-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus:hover, .btn-ghost-secondary.btn-no-focus:hover:hover, .btn-ghost-secondary.btn-no-focus.focus:hover, .btn-ghost-secondary.btn-no-focus:focus:hover {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition, .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus, .btn-ghost-secondary.btn-no-focus.btn-transition:focus {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn-check:focus + .btn-ghost-secondary.btn-no-focus.btn-transition:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:hover:hover, .btn-ghost-secondary.btn-no-focus.btn-transition.focus:hover, .btn-ghost-secondary.btn-no-focus.btn-transition:focus:hover {
  -webkit-transform: translateY(-0.1875rem);
          transform: translateY(-0.1875rem);
}

/*------------------------------------
  Button Groups
------------------------------------*/
.btn-group-segment {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #f8fafd;
  border-radius: 0.5rem;
  padding: 0.25rem 0.25rem;
}

.btn-group-segment .btn {
  color: #677788;
}

.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}

.btn-group-segment .btn:hover {
  color: #1366ff;
}

.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:nth-child(n + 3),
.btn-group-segment > :not(.btn-check) + .btn,
.btn-group-segment > .btn-group:not(:first-child) > .btn {
  border-radius: 0.5rem;
}

.btn-group-segment > .btn-check:checked + .btn,
.btn-group-segment > .btn-check:focus + .btn {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.btn-group-segment.btn-group-pills {
  border-radius: 50rem;
}

.btn-group-segment.btn-group-pills > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn {
  border-radius: 50rem;
}

/*------------------------------------
  Button Soft
------------------------------------*/
.btn-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
  border-color: transparent;
}

.btn-soft-primary:hover,
.btn-check:focus + .btn-soft-primary, .btn-soft-primary:focus {
  color: #fff;
  background-color: #377dff;
}

.btn-check:checked + .btn-soft-primary,
.btn-check:active + .btn-soft-primary, .btn-soft-primary:active, .btn-soft-primary.active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: #377dff;
  border-color: transparent;
}

.btn-soft-primary:disabled, .btn-soft-primary.disabled {
  color: #377dff;
  background-color: #377dff;
  border-color: transparent;
}

.btn-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  border-color: transparent;
}

.btn-soft-secondary:hover,
.btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:focus {
  color: #fff;
  background-color: #71869d;
}

.btn-check:checked + .btn-soft-secondary,
.btn-check:active + .btn-soft-secondary, .btn-soft-secondary:active, .btn-soft-secondary.active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #fff;
  background-color: #71869d;
  border-color: transparent;
}

.btn-soft-secondary:disabled, .btn-soft-secondary.disabled {
  color: #71869d;
  background-color: #71869d;
  border-color: transparent;
}

.btn-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}

.btn-soft-success:hover,
.btn-check:focus + .btn-soft-success, .btn-soft-success:focus {
  color: #fff;
  background-color: #00c9a7;
}

.btn-check:checked + .btn-soft-success,
.btn-check:active + .btn-soft-success, .btn-soft-success:active, .btn-soft-success.active,
.show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
  border-color: transparent;
}

.btn-soft-success:disabled, .btn-soft-success.disabled {
  color: #00c9a7;
  background-color: #00c9a7;
  border-color: transparent;
}

.btn-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
  border-color: transparent;
}

.btn-soft-info:hover,
.btn-check:focus + .btn-soft-info, .btn-soft-info:focus {
  color: #fff;
  background-color: #09a5be;
}

.btn-check:checked + .btn-soft-info,
.btn-check:active + .btn-soft-info, .btn-soft-info:active, .btn-soft-info.active,
.show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #09a5be;
  border-color: transparent;
}

.btn-soft-info:disabled, .btn-soft-info.disabled {
  color: #09a5be;
  background-color: #09a5be;
  border-color: transparent;
}

.btn-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}

.btn-soft-warning:hover,
.btn-check:focus + .btn-soft-warning, .btn-soft-warning:focus {
  color: #000;
  background-color: #f5ca99;
}

.btn-check:checked + .btn-soft-warning,
.btn-check:active + .btn-soft-warning, .btn-soft-warning:active, .btn-soft-warning.active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #000;
  background-color: #f5ca99;
  border-color: transparent;
}

.btn-soft-warning:disabled, .btn-soft-warning.disabled {
  color: #f5ca99;
  background-color: #f5ca99;
  border-color: transparent;
}

.btn-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  border-color: transparent;
}

.btn-soft-danger:hover,
.btn-check:focus + .btn-soft-danger, .btn-soft-danger:focus {
  color: #fff;
  background-color: #ed4c78;
}

.btn-check:checked + .btn-soft-danger,
.btn-check:active + .btn-soft-danger, .btn-soft-danger:active, .btn-soft-danger.active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed4c78;
  border-color: transparent;
}

.btn-soft-danger:disabled, .btn-soft-danger.disabled {
  color: #ed4c78;
  background-color: #ed4c78;
  border-color: transparent;
}

.btn-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  border-color: transparent;
}

.btn-soft-light:hover,
.btn-check:focus + .btn-soft-light, .btn-soft-light:focus {
  color: #000;
  background-color: #f7faff;
}

.btn-check:checked + .btn-soft-light,
.btn-check:active + .btn-soft-light, .btn-soft-light:active, .btn-soft-light.active,
.show > .btn-soft-light.dropdown-toggle {
  color: #000;
  background-color: #f7faff;
  border-color: transparent;
}

.btn-soft-light:disabled, .btn-soft-light.disabled {
  color: #f7faff;
  background-color: #f7faff;
  border-color: transparent;
}

.btn-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
  border-color: transparent;
}

.btn-soft-dark:hover,
.btn-check:focus + .btn-soft-dark, .btn-soft-dark:focus {
  color: #fff;
  background-color: #21325b;
}

.btn-check:checked + .btn-soft-dark,
.btn-check:active + .btn-soft-dark, .btn-soft-dark:active, .btn-soft-dark.active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #21325b;
  border-color: transparent;
}

.btn-soft-dark:disabled, .btn-soft-dark.disabled {
  color: #21325b;
  background-color: #21325b;
  border-color: transparent;
}

.btn-soft-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-primary.dropdown-toggle::after, .btn-soft-primary:hover.dropdown-toggle::after, .btn-soft-primary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after, .btn-soft-secondary:hover.dropdown-toggle::after, .btn-soft-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-success.dropdown-toggle::after, .btn-soft-success:hover.dropdown-toggle::after, .btn-soft-success:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-info.dropdown-toggle::after, .btn-soft-info:hover.dropdown-toggle::after, .btn-soft-info:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-warning.dropdown-toggle::after, .btn-soft-warning:hover.dropdown-toggle::after, .btn-soft-warning:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-danger.dropdown-toggle::after, .btn-soft-danger:hover.dropdown-toggle::after, .btn-soft-danger:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-light.dropdown-toggle::after, .btn-soft-light:hover.dropdown-toggle::after, .btn-soft-light:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-soft-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-check:focus + .btn-soft-dark.dropdown-toggle::after, .btn-soft-dark:hover.dropdown-toggle::after, .btn-soft-dark:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:focus + .btn-soft-secondary, .btn-soft-secondary:hover, .btn-soft-secondary:focus {
  color: #fff;
}

.btn-check:focus + .btn-soft-secondary.dropdown-toggle::after, .btn-soft-secondary:hover.dropdown-toggle::after, .btn-soft-secondary:focus.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

/*------------------------------------
  Button Icon
------------------------------------*/
.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}

.btn-icon > svg {
  width: 1rem;
  height: auto;
}

/*------------------------------------
  Outline Button Styles
------------------------------------*/
.btn-outline-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-primary:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-primary.dropdown-toggle::after,
.btn-check:active + .btn-outline-primary.dropdown-toggle::after, .btn-outline-primary:active.dropdown-toggle::after, .btn-outline-primary.active.dropdown-toggle::after, .btn-outline-primary.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-secondary:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-secondary.dropdown-toggle::after,
.btn-check:active + .btn-outline-secondary.dropdown-toggle::after, .btn-outline-secondary:active.dropdown-toggle::after, .btn-outline-secondary.active.dropdown-toggle::after, .btn-outline-secondary.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-success:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-success.dropdown-toggle::after,
.btn-check:active + .btn-outline-success.dropdown-toggle::after, .btn-outline-success:active.dropdown-toggle::after, .btn-outline-success.active.dropdown-toggle::after, .btn-outline-success.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-info:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-info.dropdown-toggle::after,
.btn-check:active + .btn-outline-info.dropdown-toggle::after, .btn-outline-info:active.dropdown-toggle::after, .btn-outline-info.active.dropdown-toggle::after, .btn-outline-info.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-warning:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-warning.dropdown-toggle::after,
.btn-check:active + .btn-outline-warning.dropdown-toggle::after, .btn-outline-warning:active.dropdown-toggle::after, .btn-outline-warning.active.dropdown-toggle::after, .btn-outline-warning.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-danger:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-danger.dropdown-toggle::after,
.btn-check:active + .btn-outline-danger.dropdown-toggle::after, .btn-outline-danger:active.dropdown-toggle::after, .btn-outline-danger.active.dropdown-toggle::after, .btn-outline-danger.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-light.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-light:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-light.dropdown-toggle::after,
.btn-check:active + .btn-outline-light.dropdown-toggle::after, .btn-outline-light:active.dropdown-toggle::after, .btn-outline-light.active.dropdown-toggle::after, .btn-outline-light.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  transition: all 0.2s ease-in-out;
}

.btn-outline-dark:hover::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-check:checked + .btn-outline-dark.dropdown-toggle::after,
.btn-check:active + .btn-outline-dark.dropdown-toggle::after, .btn-outline-dark:active.dropdown-toggle::after, .btn-outline-dark.active.dropdown-toggle::after, .btn-outline-dark.dropdown-toggle.show.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
}

.btn-outline-primary,
.btn-outline-secondary {
  border-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Button Sizes
------------------------------------*/
.btn-xs:not(.btn-icon) {
  font-size: 0.75rem;
  padding: 0.3125rem 0.75rem;
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.btn-icon.btn-xs > svg {
  width: 0.75rem;
  height: auto;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.btn-icon.btn-sm > svg, .btn-group-sm > .btn-icon.btn > svg {
  width: 0.8125rem;
  height: auto;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

.btn-icon.btn-lg > svg, .btn-group-lg > .btn-icon.btn > svg {
  width: 1.25rem;
  height: auto;
}

/*------------------------------------
  Card
------------------------------------*/
.card {
  border-width: 0;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.card table tr th {
  font-weight: 400;
}

.card-header {
  border-bottom-width: 0;
}

.card-footer {
  border-top-width: 0;
}

.card-subtitle {
  display: block;
  text-transform: uppercase;
  font-size: 0.8125rem;
  margin-bottom: 0.25rem;
}

.card-title {
  margin-bottom: 0;
}

.card-title:not(:last-child) {
  margin-bottom: 0.5rem;
}

.card-header-title {
  margin-bottom: 0;
}

.card-header-title + .card-text {
  margin-top: 0.25rem;
}

.card-link {
  font-weight: 600;
  white-space: nowrap;
}

.card-bordered {
  border-width: 0.0625rem;
}

.card-transition {
  transition: all 0.2s ease-in-out;
}

.card-transition:hover, .card-transition:focus {
  -webkit-transform: translateY(-0.1875rem) !important;
          transform: translateY(-0.1875rem) !important;
}

.card-ghost {
  background-color: transparent;
  box-shadow: none;
}

.card-dashed {
  border: 0.0625rem dashed rgba(33, 50, 91, 0.1);
}

.card-centered .card-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.card-group .card {
  box-shadow: none;
}

.card-group .card + .card {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card .card-header + .table-responsive {
  margin-top: -1px;
}

.card .table {
  margin-bottom: 0;
}

.card .card-table > tbody > tr:first-child > td {
  padding-top: 2rem;
}

.card .card-table > tbody > tr:last-child > td {
  padding-bottom: 2rem;
}

.card .card-table > :not(caption) > * > * {
  padding-right: 2rem;
  padding-left: 2rem;
}

.card .thead-light th:first-child {
  border-top-left-radius: 0.5rem;
}

.card .thead-light th:last-child {
  border-top-right-radius: 0.5rem;
}

.card .card-header + .table-responsive .thead-light th:first-child,
.card .card-header + .table .thead-light th:first-child {
  border-top-left-radius: 0;
}

.card .card-header + .table-responsive .thead-light th:last-child,
.card .card-header + .table .thead-light th:last-child {
  border-top-right-radius: 0;
}

.card .card-header + .table-responsive .table-thead-bordered > :not(:last-child) > :last-child > *,
.card .card-header + .table .table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card .table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top-width: 0;
}

.card-sm > .card-header,
.card-sm > .card-footer {
  padding: 1.25rem 1.25rem;
}

.card-sm > .card-body,
.card-sm > .collapse .card-body {
  padding: 1.25rem 1.25rem;
}

.card-sm .card-table > tbody > tr:first-child > td {
  padding-top: 1.25rem;
}

.card-sm .card-table > tbody > tr:last-child > td {
  padding-bottom: 1.25rem;
}

.card-sm .card-table > :not(caption) > * > * {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.card-lg > .card-header,
.card-lg > .card-footer {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.card-lg > .card-body,
.card-lg > .collapse .card-body {
  padding: 2.75rem 2.75rem;
}

.card-lg .card-table > tbody > tr:first-child > td {
  padding-top: 2.75rem;
}

.card-lg .card-table > tbody > tr:last-child > td {
  padding-bottom: 2.75rem;
}

.card-lg .card-table > :not(caption) > * > * {
  padding-right: 2.75rem;
  padding-left: 2.75rem;
}

.card-info-link {
  display: inline-block;
  text-align: center;
  border-width: 0.0625rem;
  box-shadow: none;
  border-radius: 50rem;
}

.card-info-link .card-body {
  padding: 0.8125rem 1.25rem;
}

.card-pinned {
  position: relative;
  display: block;
}

.card-pinned-top-start {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.card-pinned-top-end {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.card-pinned-bottom-start {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.card-pinned-bottom-end {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.card-flush {
  box-shadow: none;
}

.card-flush .card-header,
.card-flush .card-footer,
.card-flush .card-body,
.card-flush .collapse .card-body {
  padding-right: 0;
  padding-left: 0;
}

.card-flush > .card-img-top {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-flush.card-stretched-vertical .card-body .card-footer {
  padding-bottom: 0;
}

.card-stretched-vertical .card-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
}

.card-stretched-vertical .card-body .card-footer {
  padding: 0;
  margin-top: auto;
}

.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}

/*------------------------------------
  Card Group Break
------------------------------------*/
@media (max-width: 575.98px) {
  .card-group-sm-break {
    display: block;
  }
  .card-group-sm-break > .card {
    margin-bottom: 0;
  }
  .card-group-sm-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-break > .card + .card {
    border-left: none;
  }
  .card-group-sm-break > .card:not(:first-child):not(:last-child),
  .card-group-sm-break > .card:not(:first-child):not(:last-child) .card-header,
  .card-group-sm-break > .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group-sm-break > .card:not(:first-child):not(:last-child) .card-footer,
  .card-group-sm-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-sm-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:first-child .card-header,
  .card-group-sm-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-sm-break > .card:first-child .card-footer,
  .card-group-sm-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-header,
  .card-group-sm-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-footer,
  .card-group-sm-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media (max-width: 767.98px) {
  .card-group-md-break {
    display: block;
  }
  .card-group-md-break > .card {
    margin-bottom: 0;
  }
  .card-group-md-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-break > .card + .card {
    border-left: none;
  }
  .card-group-md-break > .card:not(:first-child):not(:last-child),
  .card-group-md-break > .card:not(:first-child):not(:last-child) .card-header,
  .card-group-md-break > .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group-md-break > .card:not(:first-child):not(:last-child) .card-footer,
  .card-group-md-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-md-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:first-child .card-header,
  .card-group-md-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-md-break > .card:first-child .card-footer,
  .card-group-md-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-header,
  .card-group-md-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-footer,
  .card-group-md-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media (max-width: 991.98px) {
  .card-group-lg-break {
    display: block;
  }
  .card-group-lg-break > .card {
    margin-bottom: 0;
  }
  .card-group-lg-break > .card:not(:last-child) {
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-break > .card + .card {
    border-left: none;
  }
  .card-group-lg-break > .card:not(:first-child):not(:last-child),
  .card-group-lg-break > .card:not(:first-child):not(:last-child) .card-header,
  .card-group-lg-break > .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group-lg-break > .card:not(:first-child):not(:last-child) .card-footer,
  .card-group-lg-break > .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-lg-break > .card:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:first-child .card-header,
  .card-group-lg-break > .card:first-child .card-img-top {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .card-group-lg-break > .card:first-child .card-footer,
  .card-group-lg-break > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-header,
  .card-group-lg-break > .card:last-child .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-footer,
  .card-group-lg-break > .card:last-child .card-img-bottom {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

/*------------------------------------
  Card Group Row
------------------------------------*/
@media (min-width: 576px) {
  .card-group-sm-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-sm-row > * {
    padding: 0;
  }
  .card-group-sm-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-sm-row > *:not(:first-child):not(:last-child),
  .card-group-sm-row > *:not(:first-child):not(:last-child) .card-header,
  .card-group-sm-row > *:not(:first-child):not(:last-child) .card-img-top,
  .card-group-sm-row > *:not(:first-child):not(:last-child) .card-footer,
  .card-group-sm-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-sm-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-row > *:first-child > .card,
  .card-group-sm-row > *:first-child > .card .card-header,
  .card-group-sm-row > *:first-child > .card .card-img-top,
  .card-group-sm-row > *:first-child > .card .card-footer,
  .card-group-sm-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-sm-row > *:last-child > .card,
  .card-group-sm-row > *:last-child > .card .card-header,
  .card-group-sm-row > *:last-child > .card .card-img-top,
  .card-group-sm-row > *:last-child > .card .card-footer,
  .card-group-sm-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-sm-2 > *:first-child > .card,
  .card-group-sm-2 > *:first-child > .card .card-header,
  .card-group-sm-2 > *:first-child > .card .card-img-top,
  .card-group-sm-2 > *:first-child > .card .card-footer,
  .card-group-sm-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-2 > *:nth-child(2) > .card,
  .card-group-sm-2 > *:nth-child(2) > .card .card-header,
  .card-group-sm-2 > *:nth-child(2) > .card .card-img-top,
  .card-group-sm-2 > *:nth-child(2) > .card .card-footer,
  .card-group-sm-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-2 > *:last-child > .card,
  .card-group-sm-2 > *:last-child > .card .card-header,
  .card-group-sm-2 > *:last-child > .card .card-img-top,
  .card-group-sm-2 > *:last-child > .card .card-footer,
  .card-group-sm-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-3 > *:first-child > .card,
  .card-group-sm-3 > *:first-child > .card .card-header,
  .card-group-sm-3 > *:first-child > .card .card-img-top,
  .card-group-sm-3 > *:first-child > .card .card-footer,
  .card-group-sm-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-3 > *:nth-child(3) > .card,
  .card-group-sm-3 > *:nth-child(3) > .card .card-header,
  .card-group-sm-3 > *:nth-child(3) > .card .card-img-top,
  .card-group-sm-3 > *:nth-child(3) > .card .card-footer,
  .card-group-sm-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-3 > *:last-child > .card,
  .card-group-sm-3 > *:last-child > .card .card-header,
  .card-group-sm-3 > *:last-child > .card .card-img-top,
  .card-group-sm-3 > *:last-child > .card .card-footer,
  .card-group-sm-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-sm-4 > *:first-child > .card,
  .card-group-sm-4 > *:first-child > .card .card-header,
  .card-group-sm-4 > *:first-child > .card .card-img-top,
  .card-group-sm-4 > *:first-child > .card .card-footer,
  .card-group-sm-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-4 > *:nth-child(4) > .card,
  .card-group-sm-4 > *:nth-child(4) > .card .card-header,
  .card-group-sm-4 > *:nth-child(4) > .card .card-img-top,
  .card-group-sm-4 > *:nth-child(4) > .card .card-footer,
  .card-group-sm-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-sm-4 > *:last-child > .card,
  .card-group-sm-4 > *:last-child > .card .card-header,
  .card-group-sm-4 > *:last-child > .card .card-img-top,
  .card-group-sm-4 > *:last-child > .card .card-footer,
  .card-group-sm-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media (min-width: 768px) {
  .card-group-md-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-md-row > * {
    padding: 0;
  }
  .card-group-md-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-md-row > *:not(:first-child):not(:last-child),
  .card-group-md-row > *:not(:first-child):not(:last-child) .card-header,
  .card-group-md-row > *:not(:first-child):not(:last-child) .card-img-top,
  .card-group-md-row > *:not(:first-child):not(:last-child) .card-footer,
  .card-group-md-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-md-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-row > *:first-child > .card,
  .card-group-md-row > *:first-child > .card .card-header,
  .card-group-md-row > *:first-child > .card .card-img-top,
  .card-group-md-row > *:first-child > .card .card-footer,
  .card-group-md-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-md-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-md-row > *:last-child > .card,
  .card-group-md-row > *:last-child > .card .card-header,
  .card-group-md-row > *:last-child > .card .card-img-top,
  .card-group-md-row > *:last-child > .card .card-footer,
  .card-group-md-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-md-2 > *:first-child > .card,
  .card-group-md-2 > *:first-child > .card .card-header,
  .card-group-md-2 > *:first-child > .card .card-img-top,
  .card-group-md-2 > *:first-child > .card .card-footer,
  .card-group-md-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-2 > *:nth-child(2) > .card,
  .card-group-md-2 > *:nth-child(2) > .card .card-header,
  .card-group-md-2 > *:nth-child(2) > .card .card-img-top,
  .card-group-md-2 > *:nth-child(2) > .card .card-footer,
  .card-group-md-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-2 > *:last-child > .card,
  .card-group-md-2 > *:last-child > .card .card-header,
  .card-group-md-2 > *:last-child > .card .card-img-top,
  .card-group-md-2 > *:last-child > .card .card-footer,
  .card-group-md-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-3 > *:first-child > .card,
  .card-group-md-3 > *:first-child > .card .card-header,
  .card-group-md-3 > *:first-child > .card .card-img-top,
  .card-group-md-3 > *:first-child > .card .card-footer,
  .card-group-md-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-3 > *:nth-child(3) > .card,
  .card-group-md-3 > *:nth-child(3) > .card .card-header,
  .card-group-md-3 > *:nth-child(3) > .card .card-img-top,
  .card-group-md-3 > *:nth-child(3) > .card .card-footer,
  .card-group-md-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-3 > *:last-child > .card,
  .card-group-md-3 > *:last-child > .card .card-header,
  .card-group-md-3 > *:last-child > .card .card-img-top,
  .card-group-md-3 > *:last-child > .card .card-footer,
  .card-group-md-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-md-4 > *:first-child > .card,
  .card-group-md-4 > *:first-child > .card .card-header,
  .card-group-md-4 > *:first-child > .card .card-img-top,
  .card-group-md-4 > *:first-child > .card .card-footer,
  .card-group-md-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-md-4 > *:nth-child(4) > .card,
  .card-group-md-4 > *:nth-child(4) > .card .card-header,
  .card-group-md-4 > *:nth-child(4) > .card .card-img-top,
  .card-group-md-4 > *:nth-child(4) > .card .card-footer,
  .card-group-md-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-md-4 > *:last-child > .card,
  .card-group-md-4 > *:last-child > .card .card-header,
  .card-group-md-4 > *:last-child > .card .card-img-top,
  .card-group-md-4 > *:last-child > .card .card-footer,
  .card-group-md-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media (min-width: 992px) {
  .card-group-lg-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-lg-row > * {
    padding: 0;
  }
  .card-group-lg-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-lg-row > *:not(:first-child):not(:last-child),
  .card-group-lg-row > *:not(:first-child):not(:last-child) .card-header,
  .card-group-lg-row > *:not(:first-child):not(:last-child) .card-img-top,
  .card-group-lg-row > *:not(:first-child):not(:last-child) .card-footer,
  .card-group-lg-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-lg-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-row > *:first-child > .card,
  .card-group-lg-row > *:first-child > .card .card-header,
  .card-group-lg-row > *:first-child > .card .card-img-top,
  .card-group-lg-row > *:first-child > .card .card-footer,
  .card-group-lg-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-lg-row > *:last-child > .card,
  .card-group-lg-row > *:last-child > .card .card-header,
  .card-group-lg-row > *:last-child > .card .card-img-top,
  .card-group-lg-row > *:last-child > .card .card-footer,
  .card-group-lg-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-lg-2 > *:first-child > .card,
  .card-group-lg-2 > *:first-child > .card .card-header,
  .card-group-lg-2 > *:first-child > .card .card-img-top,
  .card-group-lg-2 > *:first-child > .card .card-footer,
  .card-group-lg-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-2 > *:nth-child(2) > .card,
  .card-group-lg-2 > *:nth-child(2) > .card .card-header,
  .card-group-lg-2 > *:nth-child(2) > .card .card-img-top,
  .card-group-lg-2 > *:nth-child(2) > .card .card-footer,
  .card-group-lg-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-2 > *:last-child > .card,
  .card-group-lg-2 > *:last-child > .card .card-header,
  .card-group-lg-2 > *:last-child > .card .card-img-top,
  .card-group-lg-2 > *:last-child > .card .card-footer,
  .card-group-lg-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-3 > *:first-child > .card,
  .card-group-lg-3 > *:first-child > .card .card-header,
  .card-group-lg-3 > *:first-child > .card .card-img-top,
  .card-group-lg-3 > *:first-child > .card .card-footer,
  .card-group-lg-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-3 > *:nth-child(3) > .card,
  .card-group-lg-3 > *:nth-child(3) > .card .card-header,
  .card-group-lg-3 > *:nth-child(3) > .card .card-img-top,
  .card-group-lg-3 > *:nth-child(3) > .card .card-footer,
  .card-group-lg-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-3 > *:last-child > .card,
  .card-group-lg-3 > *:last-child > .card .card-header,
  .card-group-lg-3 > *:last-child > .card .card-img-top,
  .card-group-lg-3 > *:last-child > .card .card-footer,
  .card-group-lg-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-lg-4 > *:first-child > .card,
  .card-group-lg-4 > *:first-child > .card .card-header,
  .card-group-lg-4 > *:first-child > .card .card-img-top,
  .card-group-lg-4 > *:first-child > .card .card-footer,
  .card-group-lg-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-4 > *:nth-child(4) > .card,
  .card-group-lg-4 > *:nth-child(4) > .card .card-header,
  .card-group-lg-4 > *:nth-child(4) > .card .card-img-top,
  .card-group-lg-4 > *:nth-child(4) > .card .card-footer,
  .card-group-lg-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-lg-4 > *:last-child > .card,
  .card-group-lg-4 > *:last-child > .card .card-header,
  .card-group-lg-4 > *:last-child > .card .card-img-top,
  .card-group-lg-4 > *:last-child > .card .card-footer,
  .card-group-lg-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .card-group-xl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-xl-row > * {
    padding: 0;
  }
  .card-group-xl-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-xl-row > *:not(:first-child):not(:last-child),
  .card-group-xl-row > *:not(:first-child):not(:last-child) .card-header,
  .card-group-xl-row > *:not(:first-child):not(:last-child) .card-img-top,
  .card-group-xl-row > *:not(:first-child):not(:last-child) .card-footer,
  .card-group-xl-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-xl-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-row > *:first-child > .card,
  .card-group-xl-row > *:first-child > .card .card-header,
  .card-group-xl-row > *:first-child > .card .card-img-top,
  .card-group-xl-row > *:first-child > .card .card-footer,
  .card-group-xl-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-xl-row > *:last-child > .card,
  .card-group-xl-row > *:last-child > .card .card-header,
  .card-group-xl-row > *:last-child > .card .card-img-top,
  .card-group-xl-row > *:last-child > .card .card-footer,
  .card-group-xl-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xl-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xl-2 > *:first-child > .card,
  .card-group-xl-2 > *:first-child > .card .card-header,
  .card-group-xl-2 > *:first-child > .card .card-img-top,
  .card-group-xl-2 > *:first-child > .card .card-footer,
  .card-group-xl-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-2 > *:nth-child(2) > .card,
  .card-group-xl-2 > *:nth-child(2) > .card .card-header,
  .card-group-xl-2 > *:nth-child(2) > .card .card-img-top,
  .card-group-xl-2 > *:nth-child(2) > .card .card-footer,
  .card-group-xl-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-2 > *:last-child > .card,
  .card-group-xl-2 > *:last-child > .card .card-header,
  .card-group-xl-2 > *:last-child > .card .card-img-top,
  .card-group-xl-2 > *:last-child > .card .card-footer,
  .card-group-xl-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-3 > *:first-child > .card,
  .card-group-xl-3 > *:first-child > .card .card-header,
  .card-group-xl-3 > *:first-child > .card .card-img-top,
  .card-group-xl-3 > *:first-child > .card .card-footer,
  .card-group-xl-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-3 > *:nth-child(3) > .card,
  .card-group-xl-3 > *:nth-child(3) > .card .card-header,
  .card-group-xl-3 > *:nth-child(3) > .card .card-img-top,
  .card-group-xl-3 > *:nth-child(3) > .card .card-footer,
  .card-group-xl-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-3 > *:last-child > .card,
  .card-group-xl-3 > *:last-child > .card .card-header,
  .card-group-xl-3 > *:last-child > .card .card-img-top,
  .card-group-xl-3 > *:last-child > .card .card-footer,
  .card-group-xl-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xl-4 > *:first-child > .card,
  .card-group-xl-4 > *:first-child > .card .card-header,
  .card-group-xl-4 > *:first-child > .card .card-img-top,
  .card-group-xl-4 > *:first-child > .card .card-footer,
  .card-group-xl-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xl-4 > *:nth-child(4) > .card,
  .card-group-xl-4 > *:nth-child(4) > .card .card-header,
  .card-group-xl-4 > *:nth-child(4) > .card .card-img-top,
  .card-group-xl-4 > *:nth-child(4) > .card .card-footer,
  .card-group-xl-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xl-4 > *:last-child > .card,
  .card-group-xl-4 > *:last-child > .card .card-header,
  .card-group-xl-4 > *:last-child > .card .card-img-top,
  .card-group-xl-4 > *:last-child > .card .card-footer,
  .card-group-xl-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

@media (min-width: 1400px) {
  .card-group-xxl-row {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    border-radius: 0.5rem;
    margin: 0;
  }
  .card-group-xxl-row > * {
    padding: 0;
  }
  .card-group-xxl-row > * > .card {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .card-group-xxl-row > *:not(:first-child):not(:last-child),
  .card-group-xxl-row > *:not(:first-child):not(:last-child) .card-header,
  .card-group-xxl-row > *:not(:first-child):not(:last-child) .card-img-top,
  .card-group-xxl-row > *:not(:first-child):not(:last-child) .card-footer,
  .card-group-xxl-row > *:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
  .card-group-xxl-row > *:first-child > .card {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-row > *:first-child > .card,
  .card-group-xxl-row > *:first-child > .card .card-header,
  .card-group-xxl-row > *:first-child > .card .card-img-top,
  .card-group-xxl-row > *:first-child > .card .card-footer,
  .card-group-xxl-row > *:first-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-row > *:last-child > .card {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-group-xxl-row > *:last-child > .card,
  .card-group-xxl-row > *:last-child > .card .card-header,
  .card-group-xxl-row > *:last-child > .card .card-img-top,
  .card-group-xxl-row > *:last-child > .card .card-footer,
  .card-group-xxl-row > *:last-child > .card .card-img-bottom {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-row > * + * > .card {
    border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xxl-row .card-divider {
    border-left-width: 0;
    border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .card-group-xxl-2 > *:first-child > .card,
  .card-group-xxl-2 > *:first-child > .card .card-header,
  .card-group-xxl-2 > *:first-child > .card .card-img-top,
  .card-group-xxl-2 > *:first-child > .card .card-footer,
  .card-group-xxl-2 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-2 > *:nth-child(2) > .card,
  .card-group-xxl-2 > *:nth-child(2) > .card .card-header,
  .card-group-xxl-2 > *:nth-child(2) > .card .card-img-top,
  .card-group-xxl-2 > *:nth-child(2) > .card .card-footer,
  .card-group-xxl-2 > *:nth-child(2) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-2 > *:last-child > .card,
  .card-group-xxl-2 > *:last-child > .card .card-header,
  .card-group-xxl-2 > *:last-child > .card .card-img-top,
  .card-group-xxl-2 > *:last-child > .card .card-footer,
  .card-group-xxl-2 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-3 > *:first-child > .card,
  .card-group-xxl-3 > *:first-child > .card .card-header,
  .card-group-xxl-3 > *:first-child > .card .card-img-top,
  .card-group-xxl-3 > *:first-child > .card .card-footer,
  .card-group-xxl-3 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-3 > *:nth-child(3) > .card,
  .card-group-xxl-3 > *:nth-child(3) > .card .card-header,
  .card-group-xxl-3 > *:nth-child(3) > .card .card-img-top,
  .card-group-xxl-3 > *:nth-child(3) > .card .card-footer,
  .card-group-xxl-3 > *:nth-child(3) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-3 > *:last-child > .card,
  .card-group-xxl-3 > *:last-child > .card .card-header,
  .card-group-xxl-3 > *:last-child > .card .card-img-top,
  .card-group-xxl-3 > *:last-child > .card .card-footer,
  .card-group-xxl-3 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .card-group-xxl-4 > *:first-child > .card,
  .card-group-xxl-4 > *:first-child > .card .card-header,
  .card-group-xxl-4 > *:first-child > .card .card-img-top,
  .card-group-xxl-4 > *:first-child > .card .card-footer,
  .card-group-xxl-4 > *:first-child > .card .card-img-bottom {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }
  .card-group-xxl-4 > *:nth-child(4) > .card,
  .card-group-xxl-4 > *:nth-child(4) > .card .card-header,
  .card-group-xxl-4 > *:nth-child(4) > .card .card-img-top,
  .card-group-xxl-4 > *:nth-child(4) > .card .card-footer,
  .card-group-xxl-4 > *:nth-child(4) > .card .card-img-bottom {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0;
  }
  .card-group-xxl-4 > *:last-child > .card,
  .card-group-xxl-4 > *:last-child > .card .card-header,
  .card-group-xxl-4 > *:last-child > .card .card-img-top,
  .card-group-xxl-4 > *:last-child > .card .card-footer,
  .card-group-xxl-4 > *:last-child > .card .card-img-bottom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
}

.card-group-row {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.5rem;
  margin: 0;
}

.card-group-row > * {
  padding: 0;
}

.card-group-row > * > .card {
  height: 100%;
  box-shadow: none;
  border-radius: 0;
}

.card-group-row > *:not(:first-child):not(:last-child),
.card-group-row > *:not(:first-child):not(:last-child) .card-header,
.card-group-row > *:not(:first-child):not(:last-child) .card-img-top,
.card-group-row > *:not(:first-child):not(:last-child) .card-footer,
.card-group-row > *:not(:first-child):not(:last-child) .card-img-bottom {
  border-radius: 0;
}

.card-group-row > *:first-child > .card {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-row > *:first-child > .card,
.card-group-row > *:first-child > .card .card-header,
.card-group-row > *:first-child > .card .card-img-top,
.card-group-row > *:first-child > .card .card-footer,
.card-group-row > *:first-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.card-group-row > *:last-child > .card {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.card-group-row > *:last-child > .card,
.card-group-row > *:last-child > .card .card-header,
.card-group-row > *:last-child > .card .card-img-top,
.card-group-row > *:last-child > .card .card-footer,
.card-group-row > *:last-child > .card .card-img-bottom {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.card-group-row > * + * > .card {
  border-left: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-group-row .card-divider {
  border-left-width: 0;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.card-group-2 > *:first-child > .card,
.card-group-2 > *:first-child > .card .card-header,
.card-group-2 > *:first-child > .card .card-img-top,
.card-group-2 > *:first-child > .card .card-footer,
.card-group-2 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.card-group-2 > *:nth-child(2) > .card,
.card-group-2 > *:nth-child(2) > .card .card-header,
.card-group-2 > *:nth-child(2) > .card .card-img-top,
.card-group-2 > *:nth-child(2) > .card .card-footer,
.card-group-2 > *:nth-child(2) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}

.card-group-2 > *:last-child > .card,
.card-group-2 > *:last-child > .card .card-header,
.card-group-2 > *:last-child > .card .card-img-top,
.card-group-2 > *:last-child > .card .card-footer,
.card-group-2 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-3 > *:first-child > .card,
.card-group-3 > *:first-child > .card .card-header,
.card-group-3 > *:first-child > .card .card-img-top,
.card-group-3 > *:first-child > .card .card-footer,
.card-group-3 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.card-group-3 > *:nth-child(3) > .card,
.card-group-3 > *:nth-child(3) > .card .card-header,
.card-group-3 > *:nth-child(3) > .card .card-img-top,
.card-group-3 > *:nth-child(3) > .card .card-footer,
.card-group-3 > *:nth-child(3) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}

.card-group-3 > *:last-child > .card,
.card-group-3 > *:last-child > .card .card-header,
.card-group-3 > *:last-child > .card .card-img-top,
.card-group-3 > *:last-child > .card .card-footer,
.card-group-3 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-group-4 > *:first-child > .card,
.card-group-4 > *:first-child > .card .card-header,
.card-group-4 > *:first-child > .card .card-img-top,
.card-group-4 > *:first-child > .card .card-footer,
.card-group-4 > *:first-child > .card .card-img-bottom {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.card-group-4 > *:nth-child(4) > .card,
.card-group-4 > *:nth-child(4) > .card .card-header,
.card-group-4 > *:nth-child(4) > .card .card-img-top,
.card-group-4 > *:nth-child(4) > .card .card-footer,
.card-group-4 > *:nth-child(4) > .card .card-img-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0;
}

.card-group-4 > *:last-child > .card,
.card-group-4 > *:last-child > .card .card-header,
.card-group-4 > *:last-child > .card .card-img-top,
.card-group-4 > *:last-child > .card .card-footer,
.card-group-4 > *:last-child > .card .card-img-bottom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

/*------------------------------------
  Circles Chart
-------------------------------------*/
.circles-chart {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  margin-left: auto;
  margin-right: auto;
}

.circles-chart-content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 2rem 2rem;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

/*------------------------------------
  Close Button
------------------------------------*/
.btn-close:focus {
  box-shadow: none;
}

.btn-close-light {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center;
}

/*------------------------------------
  Col Divider
------------------------------------*/
@media (min-width: 576px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;
  }
  .col-sm-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

@media (min-width: 768px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;
  }
  .col-md-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

@media (min-width: 992px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;
  }
  .col-lg-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

@media (min-width: 1200px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

@media (min-width: 1400px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xxl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.0625rem;
    height: 100%;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
  }
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.col-divider > *:not(:first-child) {
  position: relative;
}

.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(33, 50, 91, 0.1);
  content: '';
}

.col-divider-rotated > *:not(:first-child)::before {
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.col-divider > *:not(:first-child) {
  position: relative;
}

.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 1.5rem);
  height: 0.0625rem;
  background-color: rgba(33, 50, 91, 0.1);
  content: '';
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.col-divider > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.col-divider > *:first-child {
  padding-top: 0;
}

.col-divider > *:last-child {
  padding-bottom: 0;
}

@media (max-width: 575.98px) {
  .col-sm-divider > *:not(:first-child) {
    position: relative;
  }
  .col-sm-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .col-sm-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-sm-divider > *:first-child {
    padding-top: 0;
  }
  .col-sm-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .col-md-divider > *:not(:first-child) {
    position: relative;
  }
  .col-md-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .col-md-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-md-divider > *:first-child {
    padding-top: 0;
  }
  .col-md-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 991.98px) {
  .col-lg-divider > *:not(:first-child) {
    position: relative;
  }
  .col-lg-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .col-lg-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-lg-divider > *:first-child {
    padding-top: 0;
  }
  .col-lg-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 1199.98px) {
  .col-xl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .col-xl-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-xl-divider > *:first-child {
    padding-top: 0;
  }
  .col-xl-divider > *:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 1399.98px) {
  .col-xxl-divider > *:not(:first-child) {
    position: relative;
  }
  .col-xxl-divider > *:not(:first-child)::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 1.5rem);
    height: 0.0625rem;
    background-color: rgba(33, 50, 91, 0.1);
    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .col-xxl-divider > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .col-xxl-divider > *:first-child {
    padding-top: 0;
  }
  .col-xxl-divider > *:last-child {
    padding-bottom: 0;
  }
}

.col-divider > *:not(:first-child) {
  position: relative;
}

.col-divider > *:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 100%;
  background-color: rgba(33, 50, 91, 0.1);
  content: '';
}

.col-divider > *,
.col-divider > *:first-child,
.col-divider > *:last-child {
  padding-top: 0;
  padding-bottom: 0;
}

.col-divider-light > *:not(:first-child)::before {
  background-color: rgba(255, 255, 255, 0.1);
}

/*------------------------------------
  Devices
------------------------------------*/
.devices {
  max-width: 1140px;
  position: relative;
  overflow: hidden;
  padding: 0 0.75rem 7rem;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.devices .device-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-left: 5rem;
  margin-bottom: 5rem;
}

@media (max-width: 991.98px) {
  .devices .device-mobile {
    display: none;
  }
}

.devices .device-browser {
  margin-left: auto;
  margin-right: 5rem;
}

@media (max-width: 991.98px) {
  .devices .device-mobile {
    margin-right: 0.5rem;
  }
  .devices .device-browser {
    margin-right: auto;
  }
}

.devices-shadow-none .device-mobile-frame,
.devices-shadow-none .device-browser-frame {
  box-shadow: none;
}

.devices-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: end;
      align-items: flex-end;
  grid-gap: 1rem;
}

@media (max-width: 991.98px) {
  .devices-wrap {
    display: none;
  }
}

.devices-rotated {
    transform: rotate(-12deg);
}

.devices-rotated .devices-rotated-body {
    transform: translate3d(15%, 0, 0);
}

.devices-top-start-50 {
  position: absolute;
  top: 0;
  left: 50%;
}

/*------------------------------------
  Mobile Device
------------------------------------*/
.device-mobile {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 15rem;
  height: auto;
}

.device-mobile-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 2rem;
  padding: 0.3125rem;
}

.device-mobile-img {
  max-width: 100%;
  height: auto;
  border-radius: 1.6rem;
}

/*------------------------------------
  Tablet Devices
------------------------------------*/
.device-tablet {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
}

.device-tablet-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 1rem;
  padding: 0.3125rem;
}

.device-tablet-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

.device-vertical-tablet {
  position: relative;
  z-index: 1;
  max-width: 100%;
  width: 30rem;
  height: auto;
}

.device-vertical-tablet-frame {
  background: #f8fafd;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3), inset 0 -0.1875rem 0.3125rem 0 rgba(151, 164, 175, 0.5);
  border-radius: 1rem;
  padding: 0.3125rem;
}

.device-vertical-tablet-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

/*------------------------------------
  Browser Device
------------------------------------*/
.device-browser {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.device-browser-frame {
  background: #fff;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5), 0 2rem 4rem -2rem rgba(33, 50, 91, 0.3);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-img {
  max-width: 100%;
  height: auto;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  max-width: 50rem;
  background: #fff;
  border-bottom: 0.0625rem solid #f1f3f8;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 6.5rem;
}

.device-browser-header-btn-list {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 0.25rem;
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 1rem;
}

.device-browser-header-btn-list-btn {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-radius: 50%;
}

.device-browser-header-browser-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 100%;
  color: #677788;
  background-color: #e7eaf3;
  font-size: 0.5rem;
  border-radius: 0.125rem;
}

.device-browser-lg {
  width: 60rem;
}

.device-browser-lg .device-browser-header {
  max-width: 60rem;
}

/*------------------------------------
  Dropdown
------------------------------------*/
.dropdown-menu {
  box-shadow: 0 0.75rem 1rem rgba(189, 197, 209, 0.3);
  margin-top: 0.3125rem;
}

.dropdown-menu .dropdown-item.dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.dropdown-item {
  font-size: 0.875rem;
  border-radius: 0.3125rem;
}

.dropdown-item:not(:last-child) {
  margin-bottom: 0.25rem;
}

.dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-item.active:not(:focus):not(:active), .dropdown-item.active:active:focus {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.2);
}

.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
}

.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  width: 1.5rem;
  color: #677788;
}

.dropdown-toggle {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  margin-left: auto;
  padding-left: 1.25rem;
  content: '';
  border: none;
}

.dropdown-toggle[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.dropdown-toggle .dropdown-item-icon {
  width: 1.75rem;
}

.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.dropright .dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-top: .25rem;
}

.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-top: -.25rem;
}

.dropdown-card {
  padding: 0;
}

.dropdown-card .card {
  box-shadow: none;
}

.dropdown-card-shopping-cart .card > .card-footer {
  border-top-width: 0.0625rem;
}

.dropdown-course-search {
  position: static !important;
}

.dropdown-course-search .dropdown-menu {
  width: 100%;
}

/*------------------------------------
  Form Check
------------------------------------*/
.form-check-label {
  font-size: 0.875rem;
  margin-top: 0.125rem;
}

.form-check .form-check-label {
  margin-top: 0;
}

label.form-control,
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border: 1px solid rgba(33, 50, 91, 0.1);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #677788;
}

/*------------------------------------
  Form Check Card
------------------------------------*/
.form-check-card {
  position: relative;
  padding: 1rem 1rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
}

.form-check-card .form-check-input {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  margin: 0;
}

/*------------------------------------
  Form Check Bookmark
------------------------------------*/
.form-check-bookmark .form-check-input {
  width: 0;
  height: 0;
  margin: 0;
  border: none;
}

.form-check-bookmark .form-check-bookmark-active {
  color: #f5ca99;
}

.form-check-bookmark .form-check-input ~ .form-check-label .form-check-bookmark-default {
  display: block;
}

.form-check-bookmark .form-check-input ~ .form-check-label .form-check-bookmark-active {
  display: none;
}

.form-check-bookmark .form-check-input:checked ~ .form-check-label .form-check-bookmark-default {
  display: none;
}

.form-check-bookmark .form-check-input:checked ~ .form-check-label .form-check-bookmark-active {
  display: block;
}

/*------------------------------------
  Form Select
------------------------------------*/
.form-select {
  cursor: pointer;
}

/*------------------------------------
  Form Switch
------------------------------------*/
.form-switch {
  position: relative;
}

.form-switch .form-check-input {
  width: 2.8125em;
  height: 1.8125em;
  border-width: 0;
  background-color: #e7eaf3;
  margin-top: -0.25em;
  margin-right: 0.75rem;
}

.form-switch .form-check-input:active {
  -webkit-filter: 100%;
          filter: 100%;
}

.form-check-input:checked {
  background-color: #377dff;
}

.form-switch-between {
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
}

.form-switch-between .form-check-input {
  float: none;
  -ms-flex-align: center;
      align-items: center;
  margin-left: 0.5rem;
}

.form-switch-between .form-check-label {
  cursor: inherit;
}

.form-switch-promotion {
  position: relative;
  min-width: 5rem;
}

.form-switch-promotion-container {
  position: absolute;
  top: -1.75rem;
  left: 1.25rem;
}

.form-switch-promotion-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: -1rem;
}

.form-switch-promotion-arrow {
  margin-right: -1.75rem;
}

.form-switch-promotion-text {
  display: block;
  margin-top: 0.3125rem;
}

/*------------------------------------
  Form Attachment
------------------------------------*/
.form-attachment-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.form-attachment-btn-label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

/*------------------------------------
  Form Text
------------------------------------*/
.form-text {
  margin-bottom: 0;
}

/*------------------------------------
  Form Link
------------------------------------*/
.form-link {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1rem;
}

/*------------------------------------
  Input Group Merge
------------------------------------*/
.input-group-merge {
  position: relative;
  overflow: hidden;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.input-group-merge .input-group-prepend,
.input-group-merge .input-group-append {
  position: absolute;
  top: 1px;
  bottom: 1px;
  z-index: 4;
  background-color: transparent;
}

.input-group-merge .input-group-prepend.input-group-text,
.input-group-merge .input-group-append.input-group-text {
  border-width: 0;
}

.input-group-merge .input-group-prepend {
  left: 1px;
}

.input-group-merge .input-group-append {
  right: 1px;
}

.input-group-merge .form-select,
.input-group-merge .form-control {
  border-width: 0;
}

.input-group-merge .form-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 3.375rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.input-group-merge .form-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 3.375rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.input-group-merge .form-select-sm,
.input-group-merge .form-control-sm {
  min-height: calc(1.5em + 0.875rem);
}

.input-group-merge .form-select-sm:not(:first-child),
.input-group-merge .form-control-sm:not(:first-child) {
  padding-left: 3.375rem;
}

.input-group-merge .form-select-sm:not(:last-child),
.input-group-merge .form-control-sm:not(:last-child) {
  padding-right: 3.375rem;
}

.input-group-merge .form-select-lg,
.input-group-merge .form-control-lg {
  min-height: calc(1.5em + 1.375rem);
}

.was-validated .input-group-merge.is-valid {
  border-color: #00c9a7;
}

.was-validated .input-group-merge.is-valid.focus {
  box-shadow: 0 0 1rem 0 rgba(0, 201, 167, 0.25);
}

.was-validated .input-group-merge.is-invalid {
  border-color: #ed4c78;
}

.was-validated .input-group-merge.is-invalid.focus {
  box-shadow: 0 0 1rem 0 rgba(237, 76, 120, 0.25);
}

/*------------------------------------
  Input Group
------------------------------------*/
.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*------------------------------------
  Input Group Break
------------------------------------*/
.input-group-down-break {
  display: block;
}

.input-group-down-break > .form-control,
.input-group-down-break > .form-select {
  width: 100%;
}

.input-group-down-break > .form-control:first-child,
.input-group-down-break > .form-select:first-child {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-down-break > .form-select:not(:first-child):not(:last-child),
.input-group-down-break > .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-down-break > .form-select:last-child,
.input-group-down-break > .form-control:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.input-group-down-break .form-control + .form-control,
.input-group-down-break .form-control + .form-select,
.input-group-down-break .form-control-plaintext + .form-control,
.input-group-down-break .form-control-plaintext + .form-select,
.input-group-down-break .form-select + .form-control,
.input-group-down-break .form-select + .form-select {
  margin-left: 0 !important;
  margin-top: -0.0625rem;
}

@media (max-width: 575.98px) {
  .input-group-sm-down-break {
    display: block;
  }
  .input-group-sm-down-break > .form-control,
  .input-group-sm-down-break > .form-select {
    width: 100%;
  }
  .input-group-sm-down-break > .form-control:first-child,
  .input-group-sm-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-sm-down-break > .form-select:not(:first-child):not(:last-child),
  .input-group-sm-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-sm-down-break > .form-select:last-child,
  .input-group-sm-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-sm-down-break .form-control + .form-control,
  .input-group-sm-down-break .form-control + .form-select,
  .input-group-sm-down-break .form-control-plaintext + .form-control,
  .input-group-sm-down-break .form-control-plaintext + .form-select,
  .input-group-sm-down-break .form-select + .form-control,
  .input-group-sm-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 767.98px) {
  .input-group-md-down-break {
    display: block;
  }
  .input-group-md-down-break > .form-control,
  .input-group-md-down-break > .form-select {
    width: 100%;
  }
  .input-group-md-down-break > .form-control:first-child,
  .input-group-md-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-md-down-break > .form-select:not(:first-child):not(:last-child),
  .input-group-md-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-md-down-break > .form-select:last-child,
  .input-group-md-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-md-down-break .form-control + .form-control,
  .input-group-md-down-break .form-control + .form-select,
  .input-group-md-down-break .form-control-plaintext + .form-control,
  .input-group-md-down-break .form-control-plaintext + .form-select,
  .input-group-md-down-break .form-select + .form-control,
  .input-group-md-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 991.98px) {
  .input-group-lg-down-break {
    display: block;
  }
  .input-group-lg-down-break > .form-control,
  .input-group-lg-down-break > .form-select {
    width: 100%;
  }
  .input-group-lg-down-break > .form-control:first-child,
  .input-group-lg-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-lg-down-break > .form-select:not(:first-child):not(:last-child),
  .input-group-lg-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-lg-down-break > .form-select:last-child,
  .input-group-lg-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-lg-down-break .form-control + .form-control,
  .input-group-lg-down-break .form-control + .form-select,
  .input-group-lg-down-break .form-control-plaintext + .form-control,
  .input-group-lg-down-break .form-control-plaintext + .form-select,
  .input-group-lg-down-break .form-select + .form-control,
  .input-group-lg-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 1199.98px) {
  .input-group-xl-down-break {
    display: block;
  }
  .input-group-xl-down-break > .form-control,
  .input-group-xl-down-break > .form-select {
    width: 100%;
  }
  .input-group-xl-down-break > .form-control:first-child,
  .input-group-xl-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xl-down-break > .form-select:not(:first-child):not(:last-child),
  .input-group-xl-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xl-down-break > .form-select:last-child,
  .input-group-xl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-xl-down-break .form-control + .form-control,
  .input-group-xl-down-break .form-control + .form-select,
  .input-group-xl-down-break .form-control-plaintext + .form-control,
  .input-group-xl-down-break .form-control-plaintext + .form-select,
  .input-group-xl-down-break .form-select + .form-control,
  .input-group-xl-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

@media (max-width: 1399.98px) {
  .input-group-xxl-down-break {
    display: block;
  }
  .input-group-xxl-down-break > .form-control,
  .input-group-xxl-down-break > .form-select {
    width: 100%;
  }
  .input-group-xxl-down-break > .form-control:first-child,
  .input-group-xxl-down-break > .form-select:first-child {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xxl-down-break > .form-select:not(:first-child):not(:last-child),
  .input-group-xxl-down-break > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xxl-down-break > .form-select:last-child,
  .input-group-xxl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem !important;
    border-bottom-left-radius: 0.3125rem !important;
  }
  .input-group-xxl-down-break .form-control + .form-control,
  .input-group-xxl-down-break .form-control + .form-select,
  .input-group-xxl-down-break .form-control-plaintext + .form-control,
  .input-group-xxl-down-break .form-control-plaintext + .form-select,
  .input-group-xxl-down-break .form-select + .form-control,
  .input-group-xxl-down-break .form-select + .form-select {
    margin-left: 0 !important;
    margin-top: -0.0625rem;
  }
}

/*------------------------------------
  Input Group
------------------------------------*/
.input-group-add-field {
  position: relative;
  margin-top: 1rem;
}

.input-group-add-field-delete {
  position: absolute;
  top: 0.75rem;
  right: -1.25rem;
  color: #ed4c78;
}

.input-group-add-field-delete:hover {
  color: #ea2c60;
}

/*------------------------------------
  Input Card
------------------------------------*/
.input-card {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  padding: 0.6125rem 0.6125rem;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  border-radius: 0.5rem;
}

.input-card .input-card-form {
  -ms-flex: 1 0 0%;
      flex: 1 0 0%;
}

.input-card .btn {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}

.input-card .form-control {
  border-width: 0;
}

.input-card .form-control:focus {
  box-shadow: none;
}

.input-card .input-group {
  border-width: 0;
}

.input-card .input-card-form {
  position: relative;
}

.input-card .input-card-form:not(:first-child) {
  padding-left: 1rem;
}

.input-card .input-card-form:not(:first-child)::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0.0625rem;
  height: 2rem;
  background-color: rgba(33, 50, 91, 0.1);
  content: '';
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input-card .input-card-form:not(:last-child) {
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .input-card-pill {
    border-radius: 50rem;
  }
}

@media (max-width: 575.98px) {
  .input-card-sm {
    display: grid;
  }
  .input-card-sm .btn,
  .input-card-sm .input-card-form {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
  }
  .input-card-sm .input-card-form {
    padding: 1rem 0 !important;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .input-card-sm .input-card-form:first-child {
    padding-top: 0 !important;
  }
  .input-card-sm .input-card-form:not(:first-child)::before {
    display: none;
  }
}

/*------------------------------------
  Labels
------------------------------------*/
.col-form-label {
  font-size: 0.875rem;
}

.form-label-secondary {
  font-size: 0.875rem;
  color: #8c98a4;
}

.form-label-link {
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

/*------------------------------------
  Go To
------------------------------------*/
.go-to {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  z-index: 9999;
  width: 3.125rem;
  height: 3.125rem;
  background-color: rgba(113, 134, 157, 0.1);
  color: #677788;
  font-size: 1rem;
  opacity: 0.5;
  border-radius: 50%;
  transition: 0.3s ease-out;
}

.go-to:hover, .go-to:focus:hover {
  color: #fff;
  background-color: #377dff;
  opacity: 1;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3125rem;
}

.icon-circle {
  border-radius: 50%;
}

.icon.icon-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.icon.icon-sm {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.icon.icon-lg {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

/*------------------------------------
  Icon
------------------------------------*/
.icon-primary {
  color: #fff;
  border-color: #377dff;
  background-color: #377dff;
}

.icon-secondary {
  color: #fff;
  border-color: #71869d;
  background-color: #71869d;
}

.icon-success {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}

.icon-info {
  color: #fff;
  border-color: #09a5be;
  background-color: #09a5be;
}

.icon-warning {
  color: #000;
  border-color: #f5ca99;
  background-color: #f5ca99;
}

.icon-danger {
  color: #fff;
  border-color: #ed4c78;
  background-color: #ed4c78;
}

.icon-light {
  color: #000;
  border-color: #f7faff;
  background-color: #f7faff;
}

.icon-dark {
  color: #fff;
  border-color: #21325b;
  background-color: #21325b;
}

.icon-soft-primary {
  color: #377dff;
  background: rgba(55, 125, 255, 0.1);
}

.icon-soft-secondary {
  color: #71869d;
  background: rgba(113, 134, 157, 0.1);
}

.icon-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}

.icon-soft-info {
  color: #09a5be;
  background: rgba(9, 165, 190, 0.1);
}

.icon-soft-warning {
  color: #f5ca99;
  background: rgba(245, 202, 153, 0.1);
}

.icon-soft-danger {
  color: #ed4c78;
  background: rgba(237, 76, 120, 0.1);
}

.icon-soft-light {
  color: #f7faff;
  background: rgba(247, 250, 255, 0.1);
}

.icon-soft-dark {
  color: #21325b;
  background: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Legend Indicator
------------------------------------*/
.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

/*------------------------------------
  List Comment
------------------------------------*/
.list-comment {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.list-comment:first-child {
  margin-top: -2.5rem;
}

.list-comment-item {
  margin-top: 2.5rem;
}

.list-comment-item .list-comment .list-comment-item {
  padding-left: 1rem;
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
}

.list-comment-divider .list-comment-item:not(:last-child) {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 2.5rem;
}

/*------------------------------------
  List Padding
------------------------------------*/
.list-py-1 > li {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.list-py-2 > li {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.list-py-3 > li {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/*------------------------------------
  List Separator
------------------------------------*/
.list-separator {
  margin-bottom: 0;
}

.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}

.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}

.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "/";
  opacity: 0.4;
}

.list-separator .list-separator-link {
  color: #677788;
}

.list-separator .list-separator-link:hover {
  color: #1366ff;
}

.list-separator-light .list-inline-item::after {
  color: #fff;
}

.list-separator-light .list-separator-link, .list-separator-light .list-separator-link:hover {
  color: #fff;
}

.list-separator-light .list-separator-link:hover {
  text-decoration: underline;
}

/*------------------------------------
  List Group
------------------------------------*/
.list-group-item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.list-group-item.active .list-group-icon {
  color: #fff;
}

.list-group-icon {
  display: inline-block;
  color: #677788;
  width: 2rem;
  text-align: center;
  margin-right: 0.5rem;
}

.list-group-striped > li:nth-of-type(odd) {
  background-color: rgba(55, 125, 255, 0.1);
}

.list-group-sm .list-group-item {
  font-size: 0.875rem;
  padding: 0.5rem 0.5rem;
}

.list-group-lg .list-group-item {
  padding: 1.5rem 1.5rem;
}

.list-group-lg .list-group-icon {
  font-size: 1.5rem;
  width: 2.5rem;
}

.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

/*------------------------------------
  List Checked
------------------------------------*/
.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item {
  position: relative;
  color: #677788;
  padding-left: 2rem;
}

.list-checked-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.25rem 1.25rem;
  content: '';
  margin-top: 0.125rem;
}

.list-checked-item[hover]:hover {
  color: #377dff;
}

[class*="list-checked-bg-"] .list-checked-item::before,
[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23377dff'/%3e%3c/svg%3e");
}

.list-checked-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23377dff'/%3e%3c/svg%3e");
}

.list-checked-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-checked-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-checked-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-checked-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-checked-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-checked-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-checked-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-checked-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-checked-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-checked-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-checked-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-checked-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-checked-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-checked-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2321325b'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/%3e%3c/svg%3e");
}

.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='9' fill='%2321325b' fill-opacity='0.1'/%3e%3cpath d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-checked-sm .list-checked-item {
  padding-left: 1.5rem;
}

.list-checked-sm .list-checked-item::before {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  margin-top: 0.3125rem;
}

.list-checked-sm[class*="list-checked-bg-"] .list-checked-item::before, .list-checked-sm[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.25rem;
}

.list-checked-lg .list-checked-item {
  padding-left: 2.75rem;
}

.list-checked-lg .list-checked-item:not(:last-child) {
  margin-bottom: 1.25rem;
}

.list-checked-lg .list-checked-item::before {
  width: 1.75rem;
  height: 1.75rem;
  background-size: 1.75rem 1.75rem;
  margin-top: 0;
}

.list-checked-lg[class*="list-checked-bg-"] .list-checked-item::before, .list-checked-lg[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: -0.0625rem;
}

/*------------------------------------
  List Pointer
------------------------------------*/
.list-pointer {
  padding-left: 0;
  list-style: none;
}

.list-pointer-item {
  position: relative;
  color: #677788;
  padding-left: 1.75rem;
}

.list-pointer-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.list-pointer-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.25rem 1.25rem;
  content: '';
  margin-top: 0.1875rem;
}

.list-pointer-item[hover]:hover {
  color: #377dff;
}

[class*="list-pointer-bg-"] .list-pointer-item::before,
[class*="list-pointer-soft-bg-"] .list-pointer-item::before {
  margin-top: 0.25rem;
}

.list-pointer-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23377dff' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23377dff'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-primary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23377dff' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23377dff'/%3e%3c/svg%3e");
}

.list-pointer-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2371869d' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-secondary .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2371869d' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2371869d'/%3e%3c/svg%3e");
}

.list-pointer-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2300c9a7' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-success .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2300c9a7' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2300c9a7'/%3e%3c/svg%3e");
}

.list-pointer-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2309a5be' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-info .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2309a5be' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2309a5be' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2309a5be'/%3e%3c/svg%3e");
}

.list-pointer-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f5ca99' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-warning .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f5ca99' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f5ca99'/%3e%3c/svg%3e");
}

.list-pointer-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23ed4c78' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-danger .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23ed4c78' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23ed4c78'/%3e%3c/svg%3e");
}

.list-pointer-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23f7faff' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f7faff'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23000'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-light .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f7faff' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%23f7faff' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23f7faff'/%3e%3c/svg%3e");
}

.list-pointer-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%2321325b' d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3e%3c/svg%3e");
}

.list-pointer-bg-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2321325b'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%23fff'/%3e%3c/svg%3e");
}

.list-pointer-soft-bg-dark .list-pointer-item::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%2321325b' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='24' height='24' rx='12' fill='%2321325b' fill-opacity='0.1'/%3e%3cpath d='M15.78 11.28C15.9207 11.4205 15.9998 11.6112 16 11.81V12.19C15.9977 12.3884 15.9189 12.5783 15.78 12.72L10.64 17.85C10.5935 17.8969 10.5382 17.9341 10.4773 17.9595C10.4164 17.9848 10.351 17.9979 10.285 17.9979C10.219 17.9979 10.1536 17.9848 10.0927 17.9595C10.0318 17.9341 9.97648 17.8969 9.93 17.85L9.22 17.14C9.17344 17.0944 9.13644 17.0399 9.11119 16.9798C9.08594 16.9197 9.07293 16.8552 9.07293 16.79C9.07293 16.7248 9.08594 16.6603 9.11119 16.6002C9.13644 16.5401 9.17344 16.4856 9.22 16.44L13.67 12L9.22 7.56C9.17314 7.51352 9.13594 7.45822 9.11055 7.39729C9.08517 7.33636 9.0721 7.27101 9.0721 7.205C9.0721 7.139 9.08517 7.07365 9.11055 7.01272C9.13594 6.95179 9.17314 6.89649 9.22 6.85L9.93 6.15C9.97648 6.10314 10.0318 6.06594 10.0927 6.04056C10.1536 6.01517 10.219 6.00211 10.285 6.00211C10.351 6.00211 10.4164 6.01517 10.4773 6.04056C10.5382 6.06594 10.5935 6.10314 10.64 6.15L15.78 11.28Z' fill='%2321325b'/%3e%3c/svg%3e");
}

.list-pointer-sm .list-pointer-item {
  padding-left: 1.5rem;
}

.list-pointer-sm .list-pointer-item::before {
  width: 1rem;
  height: 1rem;
  background-size: 1rem 1rem;
  margin-top: 0.3125rem;
}

.list-pointer-sm[class*="list-pointer-bg-"] .list-pointer-item::before, .list-pointer-sm[class*="list-pointer-soft-bg-"] .list-pointer-item::before {
  margin-top: 0.25rem;
}

.list-pointer-lg .list-pointer-item {
  padding-left: 2.75rem;
}

.list-pointer-lg .list-pointer-item:not(:last-child) {
  margin-bottom: 1.25rem;
}

.list-pointer-lg .list-pointer-item::before {
  width: 1.75rem;
  height: 1.75rem;
  background-size: 1.75rem 1.75rem;
  margin-top: 0;
}

.list-pointer-lg[class*="list-pointer-bg-"] .list-pointer-item::before, .list-pointer-lg[class*="list-pointer-soft-bg-"] .list-pointer-item::before {
  margin-top: -0.0625rem;
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.media-viewer {
  position: relative;
  display: block;
}

.media-viewer:hover .media-viewer-icon {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.media-viewer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.media-viewer-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #377dff;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  transition: 0.3s;
}

.media-viewer-icon:hover, .media-viewer-icon:focus {
  color: #fff;
}

.media-viewer-icon-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

/*------------------------------------
  Modal
------------------------------------*/
.modal-header {
  -ms-flex-align: center;
      align-items: center;
  padding-bottom: 0;
}

.modal-header .close {
  padding: 0.25rem 0.25rem;
  margin: 0 0 0 auto;
}

.modal-footer {
  border-top-width: 0.0625rem;
}

.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-footer-text:last-child {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}

.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.6875rem;
  border-top-left-radius: 0.6875rem;
}

.modal-top-cover-avatar {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}

/*------------------------------------
  Nav
------------------------------------*/
.nav-subtitle {
  display: block;
  color: #8c98a4;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}

.nav-link.dropdown-toggle {
  -ms-flex-align: center;
      align-items: center;
}

.nav-link.active {
  color: #377dff;
}

.nav-link.active .nav-link-svg path,
.nav-item.show .nav-link-svg path {
  fill: #377dff;
}

.nav-title:last-child,
.nav-text:last-child {
  margin-bottom: 0;
}

.nav-icon {
  opacity: 0.7;
  -ms-flex: 0 0 1.9375rem;
      flex: 0 0 1.9375rem;
}

.nav.nav-link-gray .nav-link {
  color: #677788;
}

.nav.nav-link-gray .nav-link.active, .nav.nav-link-gray .nav-link:hover {
  color: #377dff;
}

.nav.nav-link-gray .nav-link.disabled {
  color: #bdc5d1;
}

.nav-tabs .nav-link {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom: 0.1875rem solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
}

.nav-tabs:not(.nav-vertical) .nav-link {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.nav-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
}

.nav-vertical.nav .nav-item:not(:last-child) {
  margin-right: 0;
}

.nav-vertical.nav .nav-link {
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
}

.nav-vertical .nav-subtitle {
  padding-left: 1rem;
}

.nav-vertical.nav-tabs {
  border-bottom-width: 0;
}

.nav-vertical.nav-tabs .nav-link {
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left: 0.1875rem solid transparent;
  margin-bottom: 0;
}

.nav-vertical.nav-tabs .nav-subtitle {
  border-left: 0.1875rem solid rgba(33, 50, 91, 0.1);
}

.nav-vertical.nav-tabs .nav-link.active,
.nav-vertical.nav-tabs .nav-item.show .nav-link {
  border-color: #377dff;
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-link {
  padding-left: 3rem;
}

.nav-vertical.nav.nav-tabs .nav-collapse .nav-collapse .nav-link {
  padding-left: 4.5rem;
}

.nav-vertical.nav-pills .nav-link.active,
.nav-vertical.nav-pills .show > .nav-link {
  color: #21325b;
  background-color: transparent;
}

.nav-vertical.nav-pills .nav-link {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;
}

.nav-vertical.nav-pills .nav-link.active {
  background-color: rgba(189, 197, 209, 0.2);
}

.nav-vertical.nav-pills .nav-link:hover, .nav-vertical.nav-pills .nav-link:focus {
  color: #21325b;
}

.nav-vertical.nav-pills .nav-link[aria-expanded="true"] {
  color: #377dff;
}

.nav-vertical.nav-pills .nav-collapse {
  position: relative;
  padding-left: 1.9375rem;
}

.nav-vertical.nav-pills .nav-collapse::before {
  position: absolute;
  top: 0;
  left: 1.3125rem;
  width: 0.125rem;
  height: calc(100% - .25rem);
  content: '';
  background-color: rgba(33, 50, 91, 0.1);
}

.nav-vertical.nav-pills .nav-link.dropdown-toggle {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.nav-pills .nav-item {
  margin: 0.25rem 0.25rem;
}

.nav-pills .nav-link {
  padding: 1rem 1rem;
}

.nav-pills .nav-link:hover {
  color: #fff;
}

.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  border-color: transparent;
}

.nav-pills-shadow .nav-link.active {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem 0.25rem;
  border-radius: 0.5rem;
}

.nav-segment:not(.nav-fill) {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.nav-segment .nav-link {
  color: #677788;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.nav-segment .nav-link:hover {
  color: #377dff;
}

.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.375rem 0rem rgba(140, 152, 164, 0.25);
}

.nav-segment.nav-pills {
  border-radius: 50rem;
}

.nav-segment.nav-pills .nav-link {
  border-radius: 50rem;
}

.nav-light .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.nav-light .nav-link.active, .nav-light .nav-link:hover {
  color: #fff;
}

.nav-light.nav-pills .nav-link:hover {
  background-color: rgba(189, 197, 209, 0.2);
}

.nav-light.nav-pills .nav-link.active, .nav-light.nav-pills .nav-link:hover {
  color: #fff;
}

.nav-sm .nav-link {
  font-size: 0.9375rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.nav-sm.nav-pills .nav-link {
  padding: 0.75rem 0.75rem;
}

.nav-lg .nav-link {
  font-size: 1rem;
}

.nav-lg.nav-pills .nav-link {
  padding: 1.5rem 1.5rem;
}

.nav-link-badge {
  margin-left: auto;
}

/*------------------------------------
  Navbar
------------------------------------*/
/* purgecss start ignore */
.navbar {
  display: block;
  z-index: 99;
}

.navbar-collapse {
  -ms-flex-align: start;
      align-items: flex-start;
}

.navbar-nav {
  -ms-flex-positive: 1;
      flex-grow: 1;
}

.navbar-shadow {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.navbar-height {
  height: 3.875rem;
}

.navbar.navbar-scrolled.navbar-light {
  background-color: #fff;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.navbar.navbar-scrolled.navbar-dark {
  background-color: #21325b;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand-logo {
  width: 100%;
  min-width: 7.5rem;
  max-width: 7.5rem;
}

.navbar-brand-collapsed,
.navbar-brand-on-scroll {
  display: none;
}

.navbar-scrolled .navbar-brand-default {
  display: none;
}

.navbar-scrolled .navbar-brand-on-scroll {
  display: inline-block;
}

.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-nav-wrap .navbar-toggler {
  margin-left: auto;
}

.navbar-nav-wrap-secondary-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 0.5rem;
}

.navbar-toggler {
  padding: 0.5rem 0.5rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .navbar-toggler-text {
  color: #21325b;
}

.navbar-toggler .navbar-toggler-default {
  display: -ms-flexbox;
  display: flex;
}

.navbar-toggler .navbar-toggler-toggled {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-default {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-toggled {
  display: -ms-flexbox;
  display: flex;
}

.navbar-topbar .navbar-toggler {
  margin-left: 0;
  font-size: 0.875rem;
}

.navbar-topbar .js-mega-menu {
  z-index: 11;
}

.navbar-topbar-toggler {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin: 0.75rem 0.75rem 0 0.75rem;
  padding-bottom: 0.75rem;
}

@-webkit-keyframes navbar-topbar-collapse-scale-up {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

@keyframes navbar-topbar-collapse-scale-up {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

.navbar-topbar-collapse .navbar-toggler {
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.navbar .navbar-nav .nav-link {
  padding: 1rem 0.75rem;
}

.navbar .nav-item:hover > .nav-link {
  color: #1366ff;
}

.navbar .dropdown-menu {
  border-top: 0.1875rem solid #377dff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar .dropdown-menu::before {
  position: absolute;
  top: -0.625rem;
  display: block;
  left: 0;
  width: 100%;
  height: 1.75rem;
  content: '';
}

.navbar-dropdown-menu-inner {
  padding: 0.75rem 0.75rem;
}

.navbar-dropdown-menu-media-link {
  display: block;
  text-decoration: none;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 197, 209, 0.2);
}

.navbar-dropdown-menu-media-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
  color: #377dff;
}

.navbar-dropdown-menu-media-link.disabled {
  pointer-events: none;
}

.navbar-dropdown-menu-media-link + .navbar-dropdown-menu-media-link {
  margin-top: 0.5rem;
}

.navbar-dropdown-menu-media-title {
  color: #1e2022;
  font-weight: 600;
}

.navbar-dropdown-menu-media-desc {
  color: #677788;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item {
  position: relative;
}

.navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
  position: absolute;
  top: -0.75rem;
  right: 0;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  width: 100%;
  content: '';
}

.navbar-dropdown-menu-promo-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  margin: 0.75rem 0.75rem;
}

.navbar-dropdown-menu-promo-item ~ .navbar-dropdown-menu-promo-item {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.navbar .navbar-dropdown-menu-borderless {
  border-top-width: 0;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-promo .navbar-dropdown-menu-promo-item {
  border-top: none;
}

.navbar-dropdown-menu-promo-link {
  display: block;
  height: 100%;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-promo-link.active {
  background-color: rgba(189, 197, 209, 0.2);
}

.navbar-dropdown-menu-promo-link.active .navbar-dropdown-menu-media-title {
  color: #377dff;
}

.navbar-dropdown-menu-promo-link.disabled {
  opacity: .7;
  pointer-events: none;
}

.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 197, 209, 0.2);
}

.navbar-dropdown-menu-promo-link:hover:not(.disabled):not(:disabled) .navbar-dropdown-menu-media-title {
  color: #377dff;
}

.navbar-dropdown-menu-banner {
  position: relative;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-banner-content {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 2rem 2rem;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.navbar-dropdown-menu-shop-banner {
  position: relative;
  background-color: #f7faff;
  padding-left: 10rem;
  border-radius: 0.5rem;
}

.navbar-dropdown-menu-shop-banner-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10rem;
}

.navbar.navbar-vertical .navbar-nav .nav-subtitle,
.navbar.navbar-vertical .navbar-nav .nav-link {
  padding: 0.3125rem 1.5rem;
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical {
  padding-right: 0;
  padding-left: 0;
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-subtitle {
  border-left-color: transparent;
}

.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-link.active,
.navbar.navbar-vertical .nav-tabs-borderless.nav-vertical .nav-item.show .nav-link {
  border-color: #377dff;
}

.navbar-input-group {
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}

.navbar-input-group input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.navbar-input-group input[type="search"]::-webkit-search-decoration,
.navbar-input-group input[type="search"]::-webkit-search-cancel-button,
.navbar-input-group input[type="search"]::-webkit-search-results-button,
.navbar-input-group input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.navbar-vertical .card .nav-tabs .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding-right: 0;
  padding-left: 1.8125rem;
  margin-left: -2rem;
}

.navbar-vertical .card .nav-tabs .nav-link:not(.active) {
  border-color: transparent;
}
.navbar-promo {
  background-color: #f8fafd;
}

.navbar-promo-inner {
  color: inherit;
  padding: 2rem 2rem;
}

.navbar-promo-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.navbar-promo-item ~ .navbar-promo-item {
  border-top: 0.0625rem solid #e7eaf3;
}

.navbar-promo-link {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.navbar-promo-link.disabled {
  opacity: .7;
  pointer-events: none;
}

.navbar-promo-link:hover .navbar-promo-title {
  color: #377dff;
}

.navbar-promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.navbar-promo-card-deck {
  display: block;
}

.navbar-promo-card-deck:not(:last-child) .navbar-promo-item {
  border-bottom: 0.0625rem solid #e7eaf3;
}

.navbar-promo-card-deck .navbar-promo-item {
  border-top: none;
}

.navbar-promo-card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}

.navbar-promo-icon {
  width: 100%;
  max-width: 2.75rem;
  margin-right: 1rem;
}

.navbar-promo-title {
  display: block;
  color: #1e2022;
  font-size: 1rem;
  font-weight: 600;
}

.navbar-promo-text {
  display: block;
  font-size: 0.875rem;
  color: #677788;
}

.navbar-promo-footer {
  background-color: #f8fafd;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.navbar-promo-footer-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-promo-footer-text {
  display: block;
  font-size: 0.8125rem;
}

.navbar-promo-footer-ver-divider {
  position: relative;
}

.navbar-promo-footer-ver-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 0.0625rem solid #e7eaf3;
  content: "";
}

.navbar-expand .navbar-promo {
  border-bottom-right-radius: 0.3125rem;
}

.navbar-expand .navbar-promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
      flex: 1 0 0%;
  -ms-flex-direction: column;
      flex-direction: column;
}

.navbar-expand .navbar-promo-card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
}

.navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
  position: relative;
}

.navbar-expand .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  border-right: 0.0625rem solid #e7eaf3;
  height: 100%;
  content: "";
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-sm .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-sm .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-sm .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-sm .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-sm .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-sm .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-sm .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-md .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-md .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-md .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-md .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-md .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-md .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-md .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-lg .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-lg .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-lg .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-lg .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-lg .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-lg .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-lg .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-xl .navbar-navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    border-top: 0.1875rem solid #377dff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0px 12px 15px rgba(140, 152, 164, 0.1);
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-xl .mega-menu-position-right-fix.dropdown-menu, .navbar-expand-xl .mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
    left: auto;
  }
  .navbar-expand-xl .mega-menu-body {
    padding: 2.5rem 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .navbar-promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-xl .navbar-promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-xl .navbar-promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link {
    position: relative;
  }
  .navbar-expand-xl .navbar-promo-card-deck .navbar-promo-item:not(:last-child) .navbar-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 0.0625rem solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm .navbar-brand,
  .navbar-expand-sm .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-sm .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-sm .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-sm .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-sm .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-sm .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-sm .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-sm .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-sm .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-sm .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-sm .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md .navbar-brand,
  .navbar-expand-md .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-md .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-md .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-md .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-md .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-md .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-md .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-md .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-md .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-md .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-md .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-md .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar {
    //padding-top: 0.5rem;
    //padding-bottom: 0.5rem;
  }
  .navbar-expand-lg .navbar-brand,
  .navbar-expand-lg .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-lg .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-lg .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-lg .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-lg .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-lg .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-lg .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-lg .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-lg .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-lg .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl .navbar-brand,
  .navbar-expand-xl .navbar-brand > img {
    width: 7rem;
  }
  .navbar-expand-xl .navbar-nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .navbar-expand-xl .navbar-nav-last-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-expand-xl .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-xl .nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-xl .dropdown-item-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-xl .mega-menu-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .navbar-expand-xl .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-xl .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
    border-left: 0.1875rem solid #e7eaf3;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .navbar-expand-xl .dropdown-menu .mega-menu-body {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
    margin-left: 1.5rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.6125rem;
  }
  .navbar-expand-xl .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
    border-bottom: 0.0625rem solid #e7eaf3;
  }
}

.navbar-expand.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-expand .navbar-brand,
.navbar-expand .navbar-brand > img {
  width: 7rem;
}

.navbar-expand .navbar-nav {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.navbar-expand .navbar-nav-last-item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.navbar-expand .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-expand .nav-link-toggle::after {
  margin-left: auto;
}

.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
}

.navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.navbar-expand .dropdown-item-toggle::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.navbar-expand .mega-menu-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.navbar-expand .dropdown-menu {
  box-shadow: none;
}

.navbar-expand .dropdown-menu:not(.navbar-promo):not(.dropdown-menu-no-border-on-mobile) {
  border-left: 0.1875rem solid #e7eaf3;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.navbar-expand .dropdown-menu .mega-menu-body {
  padding-left: 1.5rem;
}

.navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-sub-menu {
  margin-left: 1.5rem;
}

.navbar-expand .hs-has-sub-menu .hs-sub-menu .hs-has-sub-menu .dropdown-item-toggle {
  padding-right: 0.6125rem;
}

.navbar-expand .navbar-promo-card-deck:last-child .navbar-promo-item:not(:last-child) {
  border-bottom: 0.0625rem solid #e7eaf3;
}

@media (max-width: 767.98px) {
  .navbar-expand-sm .header-nav-last-item {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-xs .navbar-banner,
  .navbar-expand-xs .navbar-product-banner,
  .navbar-expand-sm .navbar-banner,
  .navbar-expand-sm .navbar-product-banner,
  .navbar-expand-md .navbar-banner,
  .navbar-expand-md .navbar-product-banner,
  .navbar-expand-lg .navbar-banner,
  .navbar-expand-lg .navbar-product-banner {
    display: none;
  }
  .navbar-expand .navbar-brand {
    padding-top: 0.5rem;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-banner,
  .navbar-expand-xl .navbar-product-banner {
    display: none;
  }
}

@media (min-width: 1200px) {
  .header .mega-menu-position-right-fix-xl.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .navbar-brand {
    padding-top: 0.5rem;
  }
  .header .mega-menu-position-right-fix-md.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}

@media (min-width: 992px) {
  [class*="navbar-expand"]:not(.navbar-expand-xl) {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-lg-collapse-block .navbar-collapse {
    display: block !important;
  }
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-absolute-sm-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-absolute-md-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-absolute-lg-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-absolute-xl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-absolute-xxl-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.navbar-absolute-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-absolute-md-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-md-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

@media (max-width: 767.98px) {
  .navbar-absolute-top .navbar-absolute-top-scroller,
  .navbar-absolute-sm-top .navbar-absolute-top-scroller,
  .navbar-absolute-lg-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar,
  .navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar,
  .navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
  .navbar-absolute-sm-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb,
  .navbar-absolute-lg-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-absolute-sm-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-absolute-lg-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .navbar-absolute-xl-top .navbar-absolute-top-scroller {
    max-height: 75vh;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-absolute-xl-top .navbar-absolute-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

/*------------------------------------
  Sticky Positions
------------------------------------*/
@media (min-width: 576px) {
  .navbar-sticky-sm-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-sticky-md-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-sticky-lg-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-sticky-xl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-sticky-xxl-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

.navbar-sticky-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .navbar-sticky-md-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-md-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

@media (max-width: 767.98px) {
  .navbar-sticky-top .navbar-sticky-top-scroller,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-sticky-sm-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb,
  .navbar-sticky-lg-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

@media (max-width: 991.98px) {
  .navbar-sticky-xl-top .navbar-sticky-top-scroller {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-sticky-xl-top .navbar-sticky-top-scroller:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

/*------------------------------------
  Navbar Expand
------------------------------------*/
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-toggler {
    -ms-flex-order: 1;
        order: 1;
  }
  .navbar-expand-sm .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-sm .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-sm .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-sm:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-sm .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-sm .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-sm .hs-sub-menu.dropdown-menu,
  .navbar-expand-sm .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-sm .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-sm .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-sm .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-sm.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-sm.navbar-end .navbar-nav {
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-sm.navbar-end .navbar-sticky-top-scroller,
  .navbar-expand-sm.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-sm.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-sm.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-sm.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-sm.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm.navbar-floating .dropdown-menu, .navbar-expand-sm.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-sm.navbar-floating .dropdown-menu:not(.hs-sub-menu),
  .navbar-expand-sm.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-sm .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
        flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-sm.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: '';
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-toggler {
    -ms-flex-order: 1;
        order: 1;
  }
  .navbar-expand-md .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-md .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-md .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-md:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-md .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-md .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-md .hs-sub-menu.dropdown-menu,
  .navbar-expand-md .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-md .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-md .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-md .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-md.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-md.navbar-end .navbar-nav {
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-md.navbar-end .navbar-sticky-top-scroller,
  .navbar-expand-md.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-md.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-md.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-md.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-md.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md.navbar-floating .dropdown-menu, .navbar-expand-md.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-md.navbar-floating .dropdown-menu:not(.hs-sub-menu),
  .navbar-expand-md.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-md .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
        flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-md.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: '';
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    -ms-flex-order: 1;
        order: 1;
  }
  .navbar-expand-lg .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-lg .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-lg:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-lg .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-lg .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-lg .hs-sub-menu.dropdown-menu,
  .navbar-expand-lg .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-lg .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-lg .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-lg .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-lg.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-lg.navbar-end .navbar-nav {
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-lg.navbar-end .navbar-sticky-top-scroller,
  .navbar-expand-lg.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-lg.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-lg.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-lg.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-lg.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg.navbar-floating .dropdown-menu, .navbar-expand-lg.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-lg.navbar-floating .dropdown-menu:not(.hs-sub-menu),
  .navbar-expand-lg.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-lg .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
        flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-lg.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: '';
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-toggler {
    -ms-flex-order: 1;
        order: 1;
  }
  .navbar-expand-xl .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-xl .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-xl .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-xl:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-xl .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-xl .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-xl .hs-sub-menu.dropdown-menu,
  .navbar-expand-xl .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-xl .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-xl .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-xl .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-xl.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-xl.navbar-end .navbar-nav {
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-xl.navbar-end .navbar-sticky-top-scroller,
  .navbar-expand-xl.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-xl.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-xl.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-xl.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-xl.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl.navbar-floating .dropdown-menu, .navbar-expand-xl.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xl.navbar-floating .dropdown-menu:not(.hs-sub-menu),
  .navbar-expand-xl.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-xl .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
        flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-xl.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: '';
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-toggler {
    -ms-flex-order: 1;
        order: 1;
  }
  .navbar-expand-xxl .navbar-toggler-wrapper {
    display: none;
  }
  .navbar-expand-xxl .navbar-collapse {
    width: auto;
    -ms-flex-order: 2;
        order: 2;
  }
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
    -ms-flex-order: 3;
        order: 3;
  }
  .navbar-expand-xxl:not(.navbar-vertical) .navbar-nav {
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-xxl .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .navbar-expand-xxl .nav-item .nav-item {
    margin-right: 0;
  }
  .navbar-expand-xxl .hs-sub-menu.dropdown-menu,
  .navbar-expand-xxl .hs-sub-menu.dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -0.6875rem;
    margin-left: 0.5rem;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
    top: 0%;
    left: 100%;
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
    top: 0;
    left: -1rem;
    width: 1rem;
    height: 100%;
  }
  .navbar-expand-xxl .navbar-topbar .navbar-toggler {
    margin-left: 0;
  }
  .navbar-expand-xxl .navbar-topbar .nav-link {
    font-size: 0.9375rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-expand-xxl .navbar-topbar .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-topbar .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-xxl.navbar-end .navbar-topbar .navbar-nav {
    -ms-flex-positive: 1;
        flex-grow: 1;
    margin-left: 0;
  }
  .navbar-expand-xxl.navbar-end .navbar-nav {
    -ms-flex-pack: end;
        justify-content: flex-end;
    margin-left: auto;
  }
  .navbar-expand-xxl.navbar-end .navbar-sticky-top-scroller,
  .navbar-expand-xxl.navbar-end .navbar-absolute-top-scroller {
    margin-left: auto;
  }
  .navbar-expand-xxl.navbar-floating {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2.5rem;
  }
  .navbar-expand-xxl.navbar-floating.navbar-scrolled {
    position: fixed;
    background-color: #fff;
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar-floating.navbar-scrolled .navbar-floating-nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xxl.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
    box-shadow: none;
  }
  .navbar-expand-xxl.navbar-floating .navbar-floating-nav {
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xxl.navbar-floating .dropdown-menu, .navbar-expand-xxl.navbar-floating .dropdown-menu[data-bs-popper] {
    margin-top: 0.5rem;
  }
  .navbar-expand-xxl.navbar-floating .dropdown-menu:not(.hs-sub-menu),
  .navbar-expand-xxl.navbar-floating .dropdown-menu .hs-mega-menu {
    margin-top: 0;
  }
  .navbar-expand-xxl .navbar-floating-nav {
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar {
    -ms-flex-flow: column;
        flex-flow: column;
    overflow-y: scroll;
    height: 100%;
    max-height: 100vh;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-collapse {
    width: 100%;
    display: block !important;
  }
  .navbar-expand-xxl.navbar-vertical.navbar-sidebar .navbar-nav {
    display: block;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
    position: relative;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -0.75rem;
    border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
    height: 100%;
    content: '';
  }
}

.navbar-expand .navbar-toggler {
  -ms-flex-order: 1;
      order: 1;
}

.navbar-expand .navbar-toggler-wrapper {
  display: none;
}

.navbar-expand .navbar-collapse {
  width: auto;
  -ms-flex-order: 2;
      order: 2;
}

.navbar-expand .navbar-nav-wrap-secondary-content {
  -ms-flex-order: 3;
      order: 3;
}

.navbar-expand:not(.navbar-vertical) .navbar-nav {
  -ms-flex-align: center;
      align-items: center;
}

.navbar-expand .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.navbar-expand .nav-item .nav-item {
  margin-right: 0;
}

.navbar-expand .hs-sub-menu.dropdown-menu,
.navbar-expand .hs-sub-menu.dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}

.navbar-expand .dropdown-menu {
  margin-top: 0.5rem;
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu {
  margin-top: -0.6875rem;
  margin-left: 0.5rem;
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu[data-bs-popper] {
  top: 0%;
  left: 100%;
}

.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu::before {
  top: 0;
  left: -1rem;
  width: 1rem;
  height: 100%;
}

.navbar-expand .navbar-topbar .navbar-toggler {
  margin-left: 0;
}

.navbar-expand .navbar-topbar .nav-link {
  font-size: 0.9375rem;
  padding: 0.5rem 0.75rem;
}

.navbar-expand .navbar-topbar .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-expand .navbar-topbar .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-expand.navbar-end .navbar-topbar .navbar-nav {
  -ms-flex-positive: 1;
      flex-grow: 1;
  margin-left: 0;
}

.navbar-expand.navbar-end .navbar-nav {
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-left: auto;
}

.navbar-expand.navbar-end .navbar-sticky-top-scroller,
.navbar-expand.navbar-end .navbar-absolute-top-scroller {
  margin-left: auto;
}

.navbar-expand.navbar-floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2.5rem;
}

.navbar-expand.navbar-floating.navbar-scrolled {
  position: fixed;
  background-color: #fff;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  margin-top: 0;
}

.navbar-expand.navbar-floating.navbar-scrolled .navbar-floating-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand.navbar-floating.navbar.navbar-scrolled .navbar-floating-nav {
  box-shadow: none;
}

.navbar-expand.navbar-floating .navbar-floating-nav {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-expand.navbar-floating .dropdown-menu, .navbar-expand.navbar-floating .dropdown-menu[data-bs-popper] {
  margin-top: 0.5rem;
}

.navbar-expand.navbar-floating .dropdown-menu:not(.hs-sub-menu),
.navbar-expand.navbar-floating .dropdown-menu .hs-mega-menu {
  margin-top: 0;
}

.navbar-expand .navbar-floating-nav {
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-expand.navbar-vertical.navbar-sidebar {
  -ms-flex-flow: column;
      flex-flow: column;
  overflow-y: scroll;
  height: 100%;
  max-height: 100vh;
}

.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-expand.navbar-vertical.navbar-sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.navbar-expand.navbar-vertical.navbar-sidebar .navbar-collapse {
  width: 100%;
  display: block !important;
}

.navbar-expand.navbar-vertical.navbar-sidebar .navbar-nav {
  display: block;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
}

.navbar-expand .navbar-dropdown-menu-promo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
}

.navbar-expand .navbar-dropdown-menu-promo-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
      flex: 1 0 0%;
  -ms-flex-direction: column;
      flex-direction: column;
}

.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link {
  position: relative;
}

.navbar-expand .navbar-dropdown-menu-promo-item:not(:last-child) .navbar-dropdown-menu-promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -0.75rem;
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
  height: 100%;
  content: '';
}

.navbar-expand .navbar-nav-wrap-secondary-content {
  margin-left: auto;
}

.navbar-expand .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
  margin-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  background-color: #fff;
}

.navbar-expand .navbar-nav {
  padding: 1rem 1rem;
}

.navbar-expand .navbar-nav .nav-subtitle,
.navbar-expand .navbar-nav .nav-link {
  padding: 0.5rem 0;
}

.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
  min-width: 100% !important;
}

.navbar-expand .navbar-topbar-collapse {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  height: auto;
  max-height: calc(100% - 3rem);
  overflow-y: scroll;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}

.navbar-expand .navbar-topbar-collapse.collapsing,
.navbar-expand .navbar-topbar-collapse.show {
  -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
  position: relative;
  box-shadow: none;
  border-top-width: 0;
  margin-top: 0;
  border-radius: 0;
  padding-left: 1rem;
}

.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
  position: absolute;
  top: 0;
  left: 0.4375rem;
  width: 0.125rem;
  height: calc(100% - .25rem);
  content: '';
  background-color: rgba(33, 50, 91, 0.1);
}

.navbar-expand .navbar-dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-dropdown-sub-menu {
  position: static;
  box-shadow: none;
  padding: 0;
  margin-top: 0;
}

.navbar-expand.navbar-dark .navbar-nav .dropdown-menu {
  border-left-width: 0;
}

.navbar-expand .hs-sub-menu-opened > .dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.navbar-expand .dropdown-menu .dropdown-item.dropdown-toggle::after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.navbar-expand .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.navbar-expand .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.navbar-expand .hs-mega-menu .hs-mega-menu,
.navbar-expand .hs-mega-menu .hs-sub-menu,
.navbar-expand .hs-menu-vertical .hs-mega-menu,
.navbar-expand .hs-menu-vertical .hs-sub-menu,
.navbar-expand .hs-sub-menu .hs-mega-menu,
.navbar-expand .hs-sub-menu .hs-sub-menu {
  left: 0;
}

.navbar-expand .navbar-topbar {
  margin-bottom: 0.4375rem;
}

.navbar-expand.navbar-dark .navbar-nav,
.navbar-expand.navbar-dark .navbar-topbar-collapse {
  background-color: #21325b;
}

.navbar-expand.navbar-dark .navbar-topbar-toggler {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.navbar-expand.navbar-vertical .navbar-nav {
  padding: 0;
}

.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
  top: -0.375rem;
}

.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
  position: relative;
}

.navbar-expand .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
  position: absolute;
  bottom: -0.375rem;
  right: 0;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  width: 100%;
  content: '';
}

.navbar-expand .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
  border-top-width: 0;
}

.navbar-expand .navbar-absolute-top-inner {
  background-color: #fff;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-sm .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-sm .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-sm .navbar-nav .nav-subtitle,
  .navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-sm .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-sm .navbar-topbar-collapse.collapsing,
  .navbar-expand-sm .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - .25rem);
    content: '';
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-sm .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-sm.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-sm .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .navbar-expand-sm .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-sm .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-sm .hs-mega-menu .hs-mega-menu,
  .navbar-expand-sm .hs-mega-menu .hs-sub-menu,
  .navbar-expand-sm .hs-menu-vertical .hs-mega-menu,
  .navbar-expand-sm .hs-menu-vertical .hs-sub-menu,
  .navbar-expand-sm .hs-sub-menu .hs-mega-menu,
  .navbar-expand-sm .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-sm .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-sm.navbar-dark .navbar-nav,
  .navbar-expand-sm.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-sm.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-sm.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: '';
  }
  .navbar-expand-sm .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-sm .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-md .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-md .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-md .navbar-nav .nav-subtitle,
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-md .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-md .navbar-topbar-collapse.collapsing,
  .navbar-expand-md .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - .25rem);
    content: '';
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-md .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-md.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-md .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .navbar-expand-md .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-md .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-md .hs-mega-menu .hs-mega-menu,
  .navbar-expand-md .hs-mega-menu .hs-sub-menu,
  .navbar-expand-md .hs-menu-vertical .hs-mega-menu,
  .navbar-expand-md .hs-menu-vertical .hs-sub-menu,
  .navbar-expand-md .hs-sub-menu .hs-mega-menu,
  .navbar-expand-md .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-md .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-md.navbar-dark .navbar-nav,
  .navbar-expand-md.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-md.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-md.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: '';
  }
  .navbar-expand-md .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-md .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-lg .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-lg .navbar-nav .nav-subtitle,
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-lg .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-lg .navbar-topbar-collapse.collapsing,
  .navbar-expand-lg .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - .25rem);
    content: '';
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-lg .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-lg.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-lg .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .navbar-expand-lg .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-lg .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-lg .hs-mega-menu .hs-mega-menu,
  .navbar-expand-lg .hs-mega-menu .hs-sub-menu,
  .navbar-expand-lg .hs-menu-vertical .hs-mega-menu,
  .navbar-expand-lg .hs-menu-vertical .hs-sub-menu,
  .navbar-expand-lg .hs-sub-menu .hs-mega-menu,
  .navbar-expand-lg .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-lg .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-lg.navbar-dark .navbar-nav,
  .navbar-expand-lg.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-lg.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-lg.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: '';
  }
  .navbar-expand-lg .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-lg .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-xl .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xl .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-xl .navbar-nav .nav-subtitle,
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-xl .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xl .navbar-topbar-collapse.collapsing,
  .navbar-expand-xl .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - .25rem);
    content: '';
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-xl .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xl.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-xl .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .navbar-expand-xl .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-xl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-xl .hs-mega-menu .hs-mega-menu,
  .navbar-expand-xl .hs-mega-menu .hs-sub-menu,
  .navbar-expand-xl .hs-menu-vertical .hs-mega-menu,
  .navbar-expand-xl .hs-menu-vertical .hs-sub-menu,
  .navbar-expand-xl .hs-sub-menu .hs-mega-menu,
  .navbar-expand-xl .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-xl .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-xl.navbar-dark .navbar-nav,
  .navbar-expand-xl.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-xl.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-xl.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: '';
  }
  .navbar-expand-xl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-xl .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content {
    margin-left: auto;
  }
  .navbar-expand-xxl .navbar-nav-wrap-secondary-content ~ .navbar-toggler {
    margin-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xxl .navbar-nav {
    padding: 1rem 1rem;
  }
  .navbar-expand-xxl .navbar-nav .nav-subtitle,
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
  .navbar-expand-xxl .hs-mega-menu {
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .navbar-expand-xxl .navbar-topbar-collapse {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: auto;
    max-height: calc(100% - 3rem);
    overflow-y: scroll;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xxl .navbar-topbar-collapse.collapsing,
  .navbar-expand-xxl .navbar-topbar-collapse.show {
    -webkit-animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: navbar-topbar-collapse-scale-up 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    position: relative;
    box-shadow: none;
    border-top-width: 0;
    margin-top: 0;
    border-radius: 0;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu)::before {
    position: absolute;
    top: 0;
    left: 0.4375rem;
    width: 0.125rem;
    height: calc(100% - .25rem);
    content: '';
    background-color: rgba(33, 50, 91, 0.1);
  }
  .navbar-expand-xxl .navbar-dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-dropdown-sub-menu {
    position: static;
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar-dark .navbar-nav .dropdown-menu {
    border-left-width: 0;
  }
  .navbar-expand-xxl .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-xxl .dropdown-menu .dropdown-item.dropdown-toggle::after {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .navbar-expand-xxl .dropdown-menu .hs-sub-menu-opened > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-xxl .dropdown-menu .navbar-dropdown-submenu-item.show.dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .navbar-expand-xxl .hs-mega-menu .hs-mega-menu,
  .navbar-expand-xxl .hs-mega-menu .hs-sub-menu,
  .navbar-expand-xxl .hs-menu-vertical .hs-mega-menu,
  .navbar-expand-xxl .hs-menu-vertical .hs-sub-menu,
  .navbar-expand-xxl .hs-sub-menu .hs-mega-menu,
  .navbar-expand-xxl .hs-sub-menu .hs-sub-menu {
    left: 0;
  }
  .navbar-expand-xxl .navbar-topbar {
    margin-bottom: 0.4375rem;
  }
  .navbar-expand-xxl.navbar-dark .navbar-nav,
  .navbar-expand-xxl.navbar-dark .navbar-topbar-collapse {
    background-color: #21325b;
  }
  .navbar-expand-xxl.navbar-dark .navbar-topbar-toggler {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-expand-xxl.navbar-vertical .navbar-nav {
    padding: 0;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item::after {
    top: -0.375rem;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item {
    position: relative;
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:first-child .navbar-dropdown-menu-promo-item::after {
    position: absolute;
    bottom: -0.375rem;
    right: 0;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
    width: 100%;
    content: '';
  }
  .navbar-expand-xxl .navbar-dropdown-menu-promo:not(:first-child) .navbar-dropdown-menu-promo-item:first-child::after {
    border-top-width: 0;
  }
  .navbar-expand-xxl .navbar-absolute-top-inner {
    background-color: #fff;
  }
}

.navbar-expand .navbar-nav .nav-subtitle,
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

/*------------------------------------
  Navbar Sidebar
------------------------------------*/
.navbar-sidebar-aside-content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

@media (min-width: 576px) {
  .navbar-sidebar-aside-sm .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-sm .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}

@media (min-width: 768px) {
  .navbar-sidebar-aside-md .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-md .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}

@media (min-width: 992px) {
  .navbar-sidebar-aside-lg .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-lg .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}

@media (min-width: 1200px) {
  .navbar-sidebar-aside-xl .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-xl .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}

@media (min-width: 1400px) {
  .navbar-sidebar-aside-xxl .navbar-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17rem;
    padding: 0;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-collapse {
    width: 100%;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 17rem;
    height: 5rem;
    background-color: #fff;
    z-index: 99;
    padding: 1.25rem 1.5rem;
    border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -ms-flex-align: center;
        align-items: center;
    margin-right: 0;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-nav {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-sidebar-aside-body {
    padding-top: 6.25rem;
  }
  .navbar-sidebar-aside-xxl .navbar-sidebar-aside-content {
    margin-left: 17rem;
  }
}

.navbar-sidebar-aside .navbar-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17rem;
  padding: 0;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-collapse {
  width: 100%;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 17rem;
  height: 5rem;
  background-color: #fff;
  z-index: 99;
  padding: 1.25rem 1.5rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: center;
      align-items: center;
  margin-right: 0;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-nav {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-sidebar-aside-body {
  padding-top: 6.25rem;
}

.navbar-sidebar-aside .navbar-sidebar-aside-content {
  margin-left: 17rem;
}

.navbar-sidebar-aside .navbar-sidebar .navbar-brand,
.navbar-sidebar-aside .navbar-sidebar .navbar-brand-badge {
  display: none;
}

.navbar-sidebar-aside .nav-segment {
  margin: 1.25rem 1.5rem;
}

@media (max-width: 575.98px) {
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand,
  .navbar-sidebar-aside-sm .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-sm .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand,
  .navbar-sidebar-aside-md .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-md .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}

@media (max-width: 991.98px) {
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand,
  .navbar-sidebar-aside-lg .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-lg .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}

@media (max-width: 1199.98px) {
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand,
  .navbar-sidebar-aside-xl .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-xl .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}

@media (max-width: 1399.98px) {
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand,
  .navbar-sidebar-aside-xxl .navbar-sidebar .navbar-brand-badge {
    display: none;
  }
  .navbar-sidebar-aside-xxl .nav-segment {
    margin: 1.25rem 1.5rem;
  }
}

/*------------------------------------
  Navbar Skins
------------------------------------*/
.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #677788;
}

.navbar-light .navbar-toggler {
  color: #677788;
  border-color: rgba(33, 50, 91, 0.1);
}

.navbar-light .navbar-nav .nav-link {
  color: #677788;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #377dff;
}

.navbar-light .navbar-nav .nav-link.active .dropdown-item-icon {
  color: #377dff;
  opacity: 1;
}

.navbar-dark .navbar-nav .nav-item:hover .nav-link, .navbar-dark .navbar-nav .nav-item:hover .nav-link:hover, .navbar-dark .navbar-nav .nav-item:hover .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-toggler-text {
  color: #fff;
}

.navbar-dark .nav-link.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='rgba(255, 255, 255, 0.55)' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>");
}

.navbar-dark.navbar-fullscreen.navbar-expand .navbar-nav {
  background-color: transparent;
}

.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover, .navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover:hover, .navbar-dark.navbar-fullscreen .navbar-nav .nav-link:hover:focus,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus:hover,
.navbar-dark.navbar-fullscreen .navbar-nav .nav-link:focus:focus {
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Navbar Helpers
------------------------------------*/
.navbar-invisible {
  display: none;
}

.navbar-moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

.navbar-faded {
  opacity: 0;
  visibility: hidden;
}

.navbar-section-hidden {
  position: relative;
}

.navbar[data-hs-header-options*="fixMoment"] {
  transition: 0.3s ease;
}

.navbar.navbar-untransitioned {
  transition: none;
}

.navbar.navbar-scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}

.navbar.navbar-scrolled .navbar-topbar {
  display: none;
}

.navbar-fix-top {
  position: fixed;
}

.navbar.navbar-fix-top[data-hs-header-options*="effectCompensation"] {
  transition: none;
}

/* purgecss end ignore */

/*------------------------------------
  Fullscreen
------------------------------------*/
.navbar-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-fullscreen .navbar-collapse {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.navbar-fullscreen .navbar-collapse.show {
  opacity: 1;
  visibility: visible;
  background-color: rgba(33, 50, 91, 0.9);
}

.navbar-fullscreen .navbar-toggler {
  display: block;
  z-index: 999;
}

.navbar-fullscreen .navbar-collapse .container, .navbar-fullscreen .navbar-collapse .container > *, .navbar-fullscreen .navbar-collapse .container > * > * {
  height: 100%;
}

.navbar-fullscreen .navbar-nav {
  display: block;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 0;
}

.navbar-fullscreen .navbar-nav .nav-item .nav-item .nav-link {
  font-size: 1rem;
}

.navbar-fullscreen .navbar-nav .navbar-fullscreen-collapse {
  border-left: 0.1875rem solid rgba(255, 255, 255, 0.4);
}

.navbar-fullscreen .navbar-nav .navbar-fullscreen-collapse {
  padding-left: 1rem;
}

.navbar-fullscreen .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.5rem;
}

.navbar-fullscreen .navbar-nav > .nav-item .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-fullscreen .navbar-nav .dropdown-item, .navbar-fullscreen .navbar-nav .dropdown-item.active {
  color: #fff;
}

.navbar-fullscreen .navbar-fullscreen-scroller {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 12.5vh;
}

.navbar-fullscreen .navbar-fullscreen-scroller::-webkit-scrollbar {
  width: 0.6125rem;
}

.navbar-fullscreen .navbar-fullscreen-scroller::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}

.navbar-fullscreen .navbar-fullscreen-scroller:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*------------------------------------
  Offcanvas
------------------------------------*/
.offcanvas-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.offcanvas-navbar-search {
  bottom: auto;
  height: auto;
}

/*------------------------------------
  Page Header
------------------------------------*/
.page-header {
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 2rem;
  margin-bottom: 1rem;
}

.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}

.page-header-title {
  margin-bottom: 0.25rem;
}

.page-header-text {
  margin-bottom: 0.25rem;
}

.page-header-tabs {
  margin-bottom: -2rem;
  border-bottom-width: 0;
}

.hs-nav-scroller-horizontal .page-header-tabs {
  margin-bottom: -2rem;
}

.page-header-reset {
  border-bottom-width: 0;
  padding-bottom: 0;
}

/*------------------------------------
  Page Preloader
------------------------------------*/
.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
}

.page-preloader-middle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-item:not(:last-child) {
  margin-right: 0.25rem;
}

.page-link {
  min-width: 2.25rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.3125rem;
}

/*------------------------------------
  Popover
------------------------------------*/
.popover {
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
}

.popover-header {
  font-size: 1.25rem;
  font-weight: 600;
}

/*------------------------------------
  Profile Cover
------------------------------------*/
.profile-cover {
  position: relative;
  height: 7.5rem;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
}

.profile-cover-content {
  position: relative;
  z-index: 1;
}

.profile-cover-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.5rem;
  background-color: #e7eaf3;
  border-radius: 0.5rem;
}

.profile-cover-img {
  width: 100%;
  height: 7.5rem;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: top;
  border-radius: 0.5rem;
}

.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  padding: 0.75rem 0.75rem;
  margin-top: -1.75rem;
}

.profile-cover-content {
  padding: 1rem 1rem;
}

.profile-cover-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 992px) {
  .profile-cover {
    height: 10rem;
  }
  .profile-cover-img-wrapper {
    height: 10rem;
  }
  .profile-cover-img {
    height: 10rem;
  }
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: end;
      justify-content: flex-end;
  background-color: #e7eaf3;
  width: 0.5rem;
  height: 12.5rem;
}

/*------------------------------------
  Shapes
------------------------------------*/
.shape-container {
  position: relative;
}

.shape[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}

.shape {
  position: absolute;
  z-index: -1;
}

.shape-top {
  top: 0;
  left: 0;
  right: 0;
}

.shape-top > svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.shape-bottom > svg {
  width: 100%;
  height: auto;
  margin-bottom: -1px;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

.shape-start {
  top: 0;
  left: 0;
  bottom: 0;
}

.shape-start > svg {
  width: auto;
  height: 100%;
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

.shape-end {
  top: 0;
  right: 0;
  bottom: 0;
}

.shape-end > svg {
  width: auto;
  height: 100%;
  -webkit-transform-origin: right center;
          transform-origin: right center;
}

/*------------------------------------
  Step
------------------------------------*/
.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-right: calc(1.5rem / -2);
  margin-left: calc(1.5rem / -2);
}

.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}

.step-icon-border {
  border: 0.125rem solid rgba(33, 50, 91, 0.1);
}

.step-title {
  display: block;
  color: #1e2022;
  font-weight: 600;
}

.step-text:last-child {
  color: #677788;
  margin-bottom: 0;
}

.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}

.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-bottom: 2.25rem;
}

.step-item-between .step-item:last-child {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
}

.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.step .step-content {
  -ms-flex: 1;
      flex: 1;
}

.step-item.collapse:not(.show) {
  display: none;
}

.step-item .step-title-description {
  display: none;
}

.step-item.focus .step-title-description {
  display: block;
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step .step-avatar {
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.step .step-avatar::after {
  position: absolute;
  top: 3.875rem;
  left: 1.5625rem;
  height: calc(100% - 2.375rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

/*------------------------------------
  Step Avatar
------------------------------------*/
.step-avatar-xs .step-avatar, .step-avatar-xs.step-avatar {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.step-avatar-xs .step-avatar::after, .step-avatar-xs.step-avatar::after {
  top: 2.5rem;
  left: 0.8125rem;
  width: 1.625rem;
  height: calc(100% - 1rem);
}

.step-avatar-xs .step-divider::after {
  left: 0.875rem;
}

.step-avatar-sm .step-avatar, .step-avatar-sm.step-avatar {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.step-avatar-sm .step-avatar::after, .step-avatar-sm.step-avatar::after {
  top: 3.25rem;
  left: 1.1875rem;
  width: 1.625rem;
  height: calc(100% - 1.75rem);
}

.step-avatar-sm .step-divider::after {
  left: 1.25rem;
}

.step-avatar-lg .step-avatar, .step-avatar-lg.step-avatar {
  font-size: 1.25rem;
  width: 5.5rem;
  height: 5.5rem;
}

.step-avatar-lg .step-avatar::after, .step-avatar-lg.step-avatar::after {
  top: 6.25rem;
  left: 2.6875rem;
  width: 1.625rem;
  height: calc(100% - 4.75rem);
}

.step-avatar-lg .step-divider::after {
  left: 2.75rem;
}

/*------------------------------------
  Step Divider
------------------------------------*/
.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-size: 0.8125rem;
  font-weight: 600;
}

.step-divider::after {
  position: absolute;
  top: 1.75rem;
  left: 1.5625rem;
  height: calc(100% - 0.25rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

/*------------------------------------
  Step Icon
------------------------------------*/
.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step .step-icon::after {
  position: absolute;
  top: 3.875rem;
  left: 1.5625rem;
  height: calc(100% - 2.375rem);
  border-left: 0.125rem solid rgba(33, 50, 91, 0.1);
  content: "";
}

.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: "";
}

/*------------------------------------
  Step Icon Sizes
------------------------------------*/
.step-icon-xs .step-icon, .step-icon-xs.step-icon {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.step-icon-xs .step-icon::after, .step-icon-xs.step-icon::after {
  top: 2.5rem;
  left: 0.8125rem;
  width: 1.625rem;
  height: calc(100% - 1rem);
}

.step-icon-xs .step-divider::after {
  left: 0.875rem;
}

.step-icon-sm .step-icon, .step-icon-sm.step-icon {
  font-size: 0.8125rem;
  width: 2.5rem;
  height: 2.5rem;
}

.step-icon-sm .step-icon::after, .step-icon-sm.step-icon::after {
  top: 3.25rem;
  left: 1.1875rem;
  width: 1.625rem;
  height: calc(100% - 1.75rem);
}

.step-icon-sm .step-divider::after {
  left: 1.25rem;
}

.step-icon-lg .step-icon, .step-icon-lg.step-icon {
  font-size: 1.25rem;
  width: 5.5rem;
  height: 5.5rem;
}

.step-icon-lg .step-icon::after, .step-icon-lg.step-icon::after {
  top: 6.25rem;
  left: 2.6875rem;
  width: 1.625rem;
  height: calc(100% - 4.75rem);
}

.step-icon-lg .step-divider::after {
  left: 2.75rem;
}

/*------------------------------------
  Step Breakpoints
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex: 1;
        flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-sm.step-icon-xs .step-icon::after,
  .step-sm .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-sm.step-icon-sm .step-icon::after,
  .step-sm .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-sm.step-icon-lg .step-icon::after,
  .step-sm .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}

@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex: 1;
        flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-md.step-icon-xs .step-icon::after,
  .step-md .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-md.step-icon-sm .step-icon::after,
  .step-md .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-md.step-icon-lg .step-icon::after,
  .step-md .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex: 1;
        flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-lg.step-icon-xs .step-icon::after,
  .step-lg .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-lg.step-icon-sm .step-icon::after,
  .step-lg .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-lg.step-icon-lg .step-icon::after,
  .step-lg .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}

@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex: 1;
        flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.5625rem;
    left: 4.625rem;
    width: calc(100% - 4.625rem);
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    border-left: none;
  }
  .step-xl.step-icon-xs .step-icon::after,
  .step-xl .step-icon.step-icon-xs::after {
    top: 0.875rem;
    left: 3.25rem;
    width: calc(100% - 3.25rem);
  }
  .step-xl.step-icon-sm .step-icon::after,
  .step-xl .step-icon.step-icon-sm::after {
    top: 1.25rem;
    left: 4rem;
    width: calc(100% - 4rem);
  }
  .step-xl.step-icon-lg .step-icon::after,
  .step-xl .step-icon.step-icon-lg::after {
    top: 2.75rem;
    left: 7rem;
    width: calc(100% - 7rem);
  }
}

/*------------------------------------
  Step Centered
------------------------------------*/
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-sm.step-centered.step-icon-xs .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-sm.step-centered.step-icon-sm .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-sm.step-centered.step-icon-lg .step-icon::after,
  .step-sm.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}

@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-md.step-centered.step-icon-xs .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-md.step-centered.step-icon-sm .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-md.step-centered.step-icon-lg .step-icon::after,
  .step-md.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}

@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 4.625rem);
    left: calc(50% + 3.0625rem);
  }
  .step-lg.step-centered.step-icon-xs .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-xs::after {
    width: calc(100% - 3.25rem);
    left: calc(50% + 2.375rem);
  }
  .step-lg.step-centered.step-icon-sm .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-sm::after {
    width: calc(100% - 4rem);
    left: calc(50% + 2.75rem);
  }
  .step-lg.step-centered.step-icon-lg .step-icon::after,
  .step-lg.step-centered .step-icon.step-icon-lg::after {
    width: calc(100% - 7rem);
    left: calc(50% + 4.25rem);
  }
}

/*------------------------------------
  Step States
------------------------------------*/
.step .step-is-valid-icon,
.step .step-is-invalid-icon {
  display: none;
}

.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .active .step-title,
.step .active.is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-icon {
  color: #fff;
  background-color: #377dff;
}

.step .is-valid .step-title {
  color: #377dff;
}

.step .is-valid .step-is-valid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}

.step .is-invalid .step-icon {
  color: #fff;
  background-color: #ed4c78;
}

.step .is-invalid .step-title {
  color: #ed4c78;
}

.step .is-invalid .step-is-invalid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}

/*------------------------------------
  Step Colors
------------------------------------*/
.step-icon-primary {
  color: #fff;
  background-color: #377dff;
}

.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-secondary {
  color: #fff;
  background-color: #71869d;
}

.step-icon-secondary.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}

.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-info {
  color: #fff;
  background-color: #09a5be;
}

.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-warning {
  color: #000;
  background-color: #f5ca99;
}

.step-icon-warning.step-icon-pseudo::before {
  background-color: #000;
}

.step-icon-danger {
  color: #fff;
  background-color: #ed4c78;
}

.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-light {
  color: #000;
  background-color: #f7faff;
}

.step-icon-light.step-icon-pseudo::before {
  background-color: #000;
}

.step-icon-dark {
  color: #fff;
  background-color: #21325b;
}

.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}

.step-icon-soft-primary {
  color: #377dff;
  background-color: rgba(55, 125, 255, 0.1);
}

.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #377dff;
}

.step-icon-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}

.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #71869d;
}

.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}

.step-icon-soft-info {
  color: #09a5be;
  background-color: rgba(9, 165, 190, 0.1);
}

.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #09a5be;
}

.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}

.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}

.step-icon-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}

.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #ed4c78;
}

.step-icon-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
}

.step-icon-soft-light.step-icon-pseudo::before {
  background-color: #f7faff;
}

.step-icon-soft-dark {
  color: #21325b;
  background-color: rgba(33, 50, 91, 0.1);
}

.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #21325b;
}

/*------------------------------------
  Step Inline
------------------------------------*/
.step-inline .step-content-wrapper {
  -ms-flex-align: center;
      align-items: center;
}

.step-inline .step-item:last-child .step-title::after {
  display: none;
}

.step-inline .step-title {
  display: inline-block;
}

@media (min-width: 576px) {
  .step-sm.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-sm.step-inline.step-icon-xs .step-content .step-title::after,
  .step-sm.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-sm.step-inline.step-icon-sm .step-content .step-title::after,
  .step-sm.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-sm.step-inline.step-icon-lg .step-content .step-title::after,
  .step-sm.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}

@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-md.step-inline.step-icon-xs .step-content .step-title::after,
  .step-md.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-md.step-inline.step-icon-sm .step-content .step-title::after,
  .step-md.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-md.step-inline.step-icon-lg .step-content .step-title::after,
  .step-md.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}

@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-lg.step-inline.step-icon-xs .step-content .step-title::after,
  .step-lg.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-lg.step-inline.step-icon-sm .step-content .step-title::after,
  .step-lg.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-lg.step-inline.step-icon-lg .step-content .step-title::after,
  .step-lg.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}

@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-title::after {
    position: absolute;
    top: 1.5625rem;
    width: 100%;
    height: 1.625rem;
    border-top: 0.125rem solid rgba(33, 50, 91, 0.1);
    margin-left: 1.5rem;
    content: "";
  }
  .step-xl.step-inline.step-icon-xs .step-content .step-title::after,
  .step-xl.step-inline .step-icon-xs + .step-content .step-title::after {
    top: 0.875rem;
  }
  .step-xl.step-inline.step-icon-sm .step-content .step-title::after,
  .step-xl.step-inline .step-icon-sm + .step-content .step-title::after {
    top: 1.25rem;
  }
  .step-xl.step-inline.step-icon-lg .step-content .step-title::after,
  .step-xl.step-inline .step-icon-lg + .step-content .step-title::after {
    top: 2.75rem;
  }
}

/*------------------------------------
  Step Timeline
------------------------------------*/
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -2.75rem;
  }
}

@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -2.75rem;
  }
}

@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -2.75rem;
  }
}

@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.5625rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.875rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.25rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -2.75rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.5625rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.875rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.25rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -2.75rem;
  }
}

/*------------------------------------
  SVG Icon
------------------------------------*/
.svg-icon {
  display: block;
  margin-top: 0.125rem;
}

.svg-icon[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}

.svg-inline {
  display: inline-block;
}

.svg-icon > svg {
  width: 2.75rem;
  height: 2.75rem;
}

.svg-icon-xs > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.svg-icon-sm > svg {
  width: 1.75rem;
  height: 1.75rem;
}

.svg-icon-lg > svg {
  width: 3.5rem;
  height: 3.5rem;
}

/*------------------------------------
  Toasts
------------------------------------*/
.toast-header .btn-close {
  margin-right: 0;
}

/*------------------------------------
  Tables
------------------------------------*/
.table th {
  font-weight: 400;
}

.table tr {
  color: #21325b;
}

.table thead th {
  color: #1e2022;
  font-weight: 600;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(33, 50, 91, 0.1);
}

.table > :not(caption) > *:last-child > * {
  border-bottom-width: 0;
}

.table .btn {
  white-space: nowrap;
}

.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}

.table-align-middle tbody tr, .table-align-middle th, .table-align-middle td {
  vertical-align: middle;
}

.table-text-center, .table-text-center th, .table-text-center td {
  text-align: center;
}

.table-text-end, .table-text-end th, .table-text-end td {
  text-align: right;
}

.thead-light th {
  background-color: #f8fafd;
}

.table-lg > :not(caption) > * > * {
  padding: 1rem 1.5rem;
}

.table-thead-bordered > :not(:last-child) > :last-child > * {
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

.table-vertical-border-striped > thead > tr > th:not(:first-child):not(:last-child),
.table-vertical-border-striped > tbody > tr > td:not(:first-child):not(:last-child) {
  border-right: 0.0625rem solid rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Text Highlight
------------------------------------*/
[class*=text-highlight-] {
  background: left 1em/1em 0.2em;
  background-repeat: repeat-x;
}

.text-highlight-primary {
  background-image: linear-gradient(to bottom, rgba(55, 125, 255, 0.5), rgba(55, 125, 255, 0.5));
}

.text-highlight-secondary {
  background-image: linear-gradient(to bottom, rgba(113, 134, 157, 0.5), rgba(113, 134, 157, 0.5));
}

.text-highlight-success {
  background-image: linear-gradient(to bottom, rgba(0, 201, 167, 0.5), rgba(0, 201, 167, 0.5));
}

.text-highlight-info {
  background-image: linear-gradient(to bottom, rgba(9, 165, 190, 0.5), rgba(9, 165, 190, 0.5));
}

.text-highlight-warning {
  background-image: linear-gradient(to bottom, rgba(245, 202, 153, 0.5), rgba(245, 202, 153, 0.5));
}

.text-highlight-danger {
  background-image: linear-gradient(to bottom, rgba(237, 76, 120, 0.5), rgba(237, 76, 120, 0.5));
}

.text-highlight-light {
  background-image: linear-gradient(to bottom, rgba(247, 250, 255, 0.5), rgba(247, 250, 255, 0.5));
}

.text-highlight-dark {
  background-image: linear-gradient(to bottom, rgba(33, 50, 91, 0.5), rgba(33, 50, 91, 0.5));
}

/*------------------------------------
  Video Player
------------------------------------*/
.video-player {
  position: relative;
  display: inline-block;
}

.video-player-inline-btn {
  display: block;
  background-color: #000;
}

.video-player-preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.video-player-played .video-player-preview {
  opacity: 0;
  pointer-events: none;
}

.video-player-btn {
  z-index: 3;
  color: #1e2022;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.video-player-played .video-player-btn {
  -webkit-animation: videoPlayerButton 0.3s ease-in-out forwards;
          animation: videoPlayerButton 0.3s ease-in-out forwards;
  pointer-events: none;
}

.video-player-btn:hover .video-player-icon {
  color: #377dff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.video-player-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 4.25rem;
  height: 4.25rem;
  font-size: 1.25rem;
  border-radius: 50%;
  color: #1e2022;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  transition: 0.3s;
}

.video-player-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

/*------------------------------------
  Video Bg
------------------------------------*/
.video-bg {
  position: relative;
  min-height: 75vh;
}

.video-bg-content {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-bg-replacer-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

@media (min-width: 768px) {
  .video-bg-replacer-img {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .video-bg-content {
    display: none;
  }
}

.hs-video-bg-video iframe,
.hs-video-bg-video video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-start {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-end {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-y-sm-primary {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.075));
}

.gradient-y-gray {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(231, 234, 243, 0.5));
}

.gradient-y-lg-white {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
}

.gradient-y-three-sm-primary {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.075) 50%, rgba(255, 255, 255, 0));
}

.gradient-x-three-sm-primary {
  background-image: linear-gradient(to right, rgba(55, 125, 255, 0.03), rgba(9, 165, 190, 0.05) 50%, rgba(245, 202, 153, 0.05));
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*="gradient-x-overlay-"],
[class*="gradient-y-overlay-"],
[class*="gradient-y-three-overlay-"] {
  position: relative;
  z-index: 1;
}

[class*="gradient-x-overlay-"]::before,
[class*="gradient-y-overlay-"]::before,
[class*="gradient-y-three-overlay-"]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}

[class*="gradient-x-overlay-"].card::before,
[class*="gradient-y-overlay-"].card::before,
[class*="gradient-y-three-overlay-"].card::before {
  border-radius: 0.5rem;
}

.gradient-x-overlay-lg-dark-video::before {
  z-index: 2;
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.95) 0%, rgba(33, 50, 91, 0.95) 100%);
}

.gradient-x-overlay-sm-primary::before {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(55, 125, 255, 0.05));
}

.gradient-x-overlay-sm-dark::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.25) 0%, rgba(30, 32, 34, 0.25) 100%);
}

.gradient-x-overlay-lg-dark::before {
  background-image: linear-gradient(to right, rgba(33, 50, 91, 0.9) 0%, rgba(33, 50, 91, 0.9) 100%);
}

.gradient-y-overlay-lg-dark::before {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 40%, rgba(12, 13, 14, 0.6));
}

.gradient-y-overlay-lg-white::before {
  background-image: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0.9));
}

.gradient-y-overlay-sm-gray-900::before {
  background-image: linear-gradient(to bottom, rgba(30, 32, 34, 0.3), rgba(33, 50, 91, 0.2));
}

/*------------------------------------
  Link
------------------------------------*/
.link {
  font-weight: 600;
}

.link-primary:hover, .link-primary:focus {
  color: #1366ff;
}

.link-secondary:hover, .link-secondary:focus {
  color: #1366ff;
}

.link-success:hover, .link-success:focus {
  color: #1366ff;
}

.link-info:hover, .link-info:focus {
  color: #1366ff;
}

.link-warning:hover, .link-warning:focus {
  color: #1366ff;
}

.link-danger:hover, .link-danger:focus {
  color: #1366ff;
}

.link-light:hover, .link-light:focus {
  color: rgba(255, 255, 255, 0.7);
}

.link-dark:hover, .link-dark:focus {
  color: #1366ff;
}

.link-sm {
  font-size: 0.875rem;
}

.link-collapse[aria-expanded="false"] .link-collapse-default {
  display: inline-block;
}

.link-collapse[aria-expanded="false"] .link-collapse-active {
  display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse-default {
  display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse-active {
  display: inline-block;
}

/*------------------------------------
  Typography
------------------------------------*/
.text-cap {
  display: block;
  color: #1e2022;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-dark {
  color: #1e2022 !important;
}

.text-dark[href]:hover {
  color: #1366ff !important;
}

.link-secondary[href]:hover,
.text-secondary[href]:hover,
.text-muted[href]:hover,
.text-body[href]:hover {
  color: #1366ff !important;
}

a:hover .text-inherit {
  color: #1366ff !important;
}

/*------------------------------------
  Transform
------------------------------------*/
.transform-rotate-n40deg {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  -webkit-transform-origin: 30% 20%;
          transform-origin: 30% 20%;
}

.transform-rotate-n22deg {
  -webkit-transform: rotate(-22deg);
          transform: rotate(-22deg);
}

.rotated-3d-left {
  -webkit-transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
          transform: perspective(1140px) rotateX(7deg) rotateY(-17deg) rotateZ(4deg);
}

.rotated-3d-right {
  -webkit-transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
          transform: perspective(1140px) rotateX(7deg) rotateY(17deg) rotateZ(-4deg);
}

/*------------------------------------
  Divider
------------------------------------*/
.divider-start {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #8c98a4;
}

.divider-start::after {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: '';
}

.divider-start::after {
  margin-left: 1.5rem;
}

.divider-end {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #8c98a4;
}

.divider-end::before {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: '';
}

.divider-end::before {
  margin-right: 1.5rem;
}

.divider-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #8c98a4;
}

.divider-center::before, .divider-center::after {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  border-top: 0.0625rem solid rgba(33, 50, 91, 0.1);
  margin-top: 0.0625rem;
  content: '';
}

.divider-center::before {
  margin-right: 1.5rem;
}

.divider-center::after {
  margin-left: 1.5rem;
}

/*------------------------------------
  Nav Scroll Horizontal
------------------------------------*/
.hs-nav-scroller-horizontal {
  position: relative;
}

.hs-nav-scroller-horizontal .nav {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.hs-nav-scroller-horizontal .nav .nav-item {
  white-space: nowrap;
}

.hs-nav-scroller-horizontal .nav .nav-link {
  white-space: nowrap;
}

.hs-nav-scroller-horizontal .nav::-webkit-scrollbar {
  display: none;
}

.hs-nav-scroller-horizontal .nav-tabs {
  padding-bottom: .1875rem;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  position: absolute;
  height: 100%;
  z-index: 1;
  font-size: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link {
  width: 3.125rem;
  color: #677788;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link:hover {
  color: #1366ff;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
  left: 0;
  margin-left: -0.125rem;
  background-image: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link {
  padding: 0.5rem 0;
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  right: 0;
  margin-right: -0.125rem;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 50%);
}

.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
  padding: 0.5rem 0;
  text-align: right;
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link,
.hs-nav-scroller-light .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link {
  color: rgba(255, 255, 255, 0.7);
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev .hs-nav-scroller-arrow-link:hover,
.hs-nav-scroller-light .hs-nav-scroller-arrow-next .hs-nav-scroller-arrow-link:hover {
  color: #fff;
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-prev {
  background-image: linear-gradient(to right, #21325b 50%, rgba(255, 255, 255, 0) 100%);
}

.hs-nav-scroller-light .hs-nav-scroller-arrow-next {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #21325b 50%);
}

.hs-nav-scroller-vertical {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.hs-nav-scroller-vertical::-webkit-scrollbar {
  width: 0.6125rem;
}

.hs-nav-scroller-vertical::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}

.hs-nav-scroller-unfold {
  position: static;
}

/*------------------------------------
  Dropzone
------------------------------------*/
.dz-dropzone {
  cursor: pointer;
}

.dz-dropzone .dz-message {
  width: 100%;
  text-align: center;
}

.dz-dropzone .dz-details {
  margin-bottom: 1rem;
}

.dz-dropzone .dz-file-preview {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.dz-dropzone .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dz-dropzone .dz-filename {
  margin-bottom: 0.25rem;
}

.dz-dropzone .dz-img {
  max-width: 3.125rem;
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}

.dz-dropzone .dz-img-inner {
  border-radius: 0.5rem;
}

.dz-dropzone .dz-close-icon,
.dz-dropzone .dz-size {
  color: #677788;
}

.dz-dropzone .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}

.dz-dropzone .dz-size {
  font-size: 80%;
}

.dz-dropzone .dz-file-initials {
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  font-weight: 600;
  font-size: 1rem;
  color: #377dff;
  text-align: center;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}

.dz-dropzone [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}

.dz-dropzone .dz-progress {
  margin-bottom: 1rem;
}

.dz-dropzone .dz-processing .dz-error-mark,
.dz-dropzone .dz-processing .dz-success-mark {
  display: none;
}

.dz-dropzone .dz-processing.dz-error .dz-error-mark,
.dz-dropzone .dz-processing.dz-success .dz-success-mark {
  display: block;
}

.dz-dropzone .dz-processing .dz-error-mark {
  color: #ed4c78;
}

.dz-dropzone .dz-processing .dz-success-mark {
  color: #00c9a7;
}

.dz-dropzone-card {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(33, 50, 91, 0.1);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.fancybox-custom .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}

.fancybox-custom .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}

.fancybox-custom .fancybox-content {
  background-color: transparent;
}

.fancybox-custom .fancybox-bg {
  background-color: #1e2022;
}

.fancybox-custom .fancybox-button svg {
  margin-bottom: 0;
}

.fancybox-custom .fancybox-progress {
  background-color: #377dff;
}

.fancybox-blur header,
.fancybox-blur aside,
.fancybox-blur main,
.fancybox-blur footer {
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

/*------------------------------------
  Quantity Counter
------------------------------------*/
.quantity-counter {
  padding: 0.5rem 1rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.quantity-counter-btn.btn > svg {
  width: 1.25rem;
}

.form-control-quantity-counter {
  border-width: 0;
  height: auto;
  padding: 0;
}

.form-control-quantity-counter:focus {
  box-shadow: none;
}

/*------------------------------------
  Quill Editor
------------------------------------*/
.quill-custom .ql-container {
  position: static;
}

.quill-custom .ql-toolbar.ql-snow,
.quill-custom .ql-container.ql-snow {
  border-color: rgba(33, 50, 91, 0.1);
}

.quill-custom .ql-toolbar.ql-snow {
  padding: 0.75rem 1rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.quill-custom .ql-container.ql-snow {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.quill-custom .ql-formats:first-child {
  padding-left: 0;
}

.quill-custom .ql-formats:first-child button {
  margin-right: 0.5rem;
}

.quill-custom .ql-formats:first-child button:first-child {
  margin-left: -0.25rem;
}

.quill-custom .ql-editor {
  position: relative;
  padding: 0.6125rem 1rem;
}

.quill-custom .ql-editor p {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  color: #1e2022;
}

.quill-custom .ql-editor.ql-blank::before {
  left: 1rem;
  color: #8c98a4;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.quill-custom .ql-snow.ql-toolbar .ql-fill {
  fill: #677788;
}

.quill-custom .ql-snow.ql-toolbar .ql-stroke {
  stroke: #677788;
}

.quill-custom .ql-snow.ql-toolbar button:hover {
  color: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #377dff;
}

.quill-custom .ql-snow.ql-toolbar button,
.quill-custom .ql-snow .ql-toolbar button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem 0.25rem;
}

.quill-custom .ql-snow.ql-toolbar button svg,
.quill-custom .ql-snow .ql-toolbar button svg {
  margin-bottom: 0;
}

.quill-custom .ql-snow .ql-tooltip {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 1;
  min-width: 20rem;
  border-width: 0;
  text-align: center;
  box-shadow: 0rem 0.6125rem 2.5rem 0.6125rem rgba(140, 152, 164, 0.175);
  padding: 1.5rem 1.5rem;
  border-radius: 0.3125rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.quill-custom .ql-snow .ql-tooltip::before {
  display: block;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
  min-width: 20rem;
  font-size: 1rem;
  line-height: normal;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding: 0.6125rem 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview {
  display: block;
}

.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
  border-color: #377dff;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text] {
  height: auto;
  display: block;
  font-family: "Inter", sans-serif;
}

.quill-custom .ql-snow .ql-tooltip.ql-editing input[type=text]:focus {
  border-color: rgba(140, 152, 164, 0.25);
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
}

.quill-custom .ql-snow .ql-action {
  display: inline-block;
  color: #fff;
  background-color: #377dff;
  font-size: 1rem;
  line-height: normal;
  padding: 0.6125rem 1rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-action:hover {
  background-color: #1366ff;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove::before,
.quill-custom .ql-snow .ql-tooltip a.ql-action::after {
  padding-right: 0;
  margin-left: 0;
  border-right: none;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  font-size: 1rem;
  padding: 0.6125rem 1rem;
  border-radius: 0.3125rem;
  margin-left: 0.5rem;
  transition: 0.3s;
}

.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
  color: #1366ff;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Select
------------------------------------*/
.tom-select-custom .input-group-sm > .ts-control .ts-input.has-items,
.tom-select-custom .ts-control.form-select-sm .ts-input.has-items,
.tom-select-custom .ts-control.form-control-sm .ts-input.has-items {
  padding: 0.5rem 3rem 0.5rem 1rem;
}

.tom-select-custom .input-group-lg > .ts-control > .ts-input,
.tom-select-custom .ts-control.form-control-lg .ts-input,
.tom-select-custom .ts-control.form-select-lg .ts-input {
  padding: 0.75rem 3rem 0.75rem 1rem;
}

.tom-select-custom .hs-select-single-multiple .item {
  display: none !important;
}

.tom-select-custom .ts-control.form-select .ts-input,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple {
  padding: 0.6125rem 3rem 0.6125rem 1rem;
}

.tom-select-custom .ts-control.form-select .ts-input .tom-select-custom-hide,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple .tom-select-custom-hide {
  display: none;
}

.tom-select-custom .ts-control.form-select .ts-input:focus,
.tom-select-custom .ts-control.form-select .ts-input .focus,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple:focus,
.tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple .focus {
  box-shadow: none;
}

.tom-select-custom .ts-dropdown,
.tom-select-custom .ts-dropdown.form-control,
.tom-select-custom .ts-dropdown.form-select {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.tom-select-custom .ts-dropdown .create:hover,
.tom-select-custom .ts-dropdown .option:hover,
.tom-select-custom .ts-dropdown .active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}

.tom-select-custom .ts-dropdown .option,
.tom-select-custom .ts-dropdown .optgroup-header,
.tom-select-custom .ts-dropdown .no-results,
.tom-select-custom .ts-dropdown .create {
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}

.tom-select-custom .ts-dropdown .option {
  position: relative;
}

.tom-select-custom .ts-dropdown .option::after {
  display: none;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110' fill='%23377dff'%3e%3cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1rem 1rem;
  content: '';
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.tom-select-custom .ts-dropdown .option.selected::after {
  display: block;
}

.tom-select-custom .ts-input,
.tom-select-custom .ts-control.single .ts-input.input-active {
  cursor: pointer;
}

.tom-select-custom .input-group > .ts-control,
.tom-select-custom .input-group > .form-select {
  -ms-flex-positive: unset;
      flex-grow: unset;
}

.tom-select-custom .input-group > .form-select {
  width: auto;
}

.tom-select-custom .ts-control {
  display: inline-block;
}

.tom-select-custom .ts-dropdown .optgroup-header {
  font-weight: bold;
  color: #000;
}

.tom-select-custom .ts-dropdown .optgroup:before {
  display: none;
}

.tom-select-custom .ts-dropdown .optgroup .option {
  padding-left: 1.5rem;
}

.tom-select-custom .ts-control.multi .ts-input > div {
  background-color: #e7eaf3;
  font-size: 0.875rem;
  padding: 0.28rem 0.875rem;
  border-radius: 4px;
}

.tom-select-custom .ts-control.plugin-remove_button .item .remove {
  border-left: none;
  color: #71869d;
}

.tom-select-custom .ts-control.plugin-remove_button .item .remove:hover {
  background: transparent;
  color: #333;
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap {
  padding: 0.4rem;
}

.tom-select-custom .plugin-dropdown_input .dropdown-input-wrap .dropdown-input {
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
}

.tom-select-custom-with-tags .ts-control.form-select .ts-input,
.tom-select-custom-with-tags .tom-select-custom .ts-control.multi .ts-input.has-items.hs-select-single-multiple {
  padding: calc( 0.375rem - 1px - 0px) 0.75rem calc( 0.375rem - 1px - 4px - 0px);
}

.tom-select-custom-with-tags .ts-control.multi .ts-input > .item {
  color: #1e2022;
  background-color: rgba(33, 50, 91, 0.1);
}

/*------------------------------------
  Swiper
------------------------------------*/
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  z-index: 1;
}

.swiper-button-next,
.swiper-button-prev {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: #677788;
  width: 3.125rem;
  height: 3.125rem;
  background-color: #fff;
  box-shadow: 0rem 0.1875rem 0.4375rem 0rem rgba(140, 152, 164, 0.4);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  width: 1rem;
  height: 1rem;
  font-family: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  content: '';
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  box-shadow: none;
}

.swiper-button-next:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-next:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23377dff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-prev:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23677788'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-button-prev:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23377dff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-button-next-soft-white,
.swiper-button-prev-soft-white {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.swiper-button-next-soft-white:hover,
.swiper-button-prev-soft-white:hover {
  background-color: #377dff;
}

.swiper-button-next-soft-white:after, .swiper-button-next-soft-white:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.swiper-button-prev-soft-white:after, .swiper-button-prev-soft-white:hover:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.swiper-pagination {
  position: static;
  width: 100% !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 2rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 0%;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.25rem;
}

.swiper-pagination-bullet {
  position: relative;
  opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid transparent;
  background-color: transparent;
  transition: 0.2s;
}

.swiper-pagination-bullet::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: 0.2s;
  content: '';
}

.swiper-pagination-bullet:hover {
  border-color: rgba(55, 125, 255, 0.5);
}

.swiper-pagination-bullet:hover::before {
  background-color: rgba(55, 125, 255, 0.5);
}

.swiper-pagination-bullet-active, .swiper-pagination-bullet-active:hover {
  border-color: #377dff;
}

.swiper-pagination-bullet-active::before, .swiper-pagination-bullet-active:hover::before {
  background-color: #377dff;
}

.swiper-pagination-light .swiper-pagination-bullet::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-light .swiper-pagination-bullet:hover {
  border-color: #fff;
}

.swiper-pagination-light .swiper-pagination-bullet:hover::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-light .swiper-pagination-bullet-active, .swiper-pagination-light .swiper-pagination-bullet-active:hover {
  border-color: #fff;
}

.swiper-pagination-light .swiper-pagination-bullet-active::before, .swiper-pagination-light .swiper-pagination-bullet-active:hover::before {
  background-color: #fff;
}

.swiper-pagination-progress {
  cursor: pointer;
}

.swiper-pagination-progress-body {
  position: relative;
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: rgba(55, 125, 255, 0.1);
}

.swiper-pagination-progress-body-helper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background-color: #377dff;
  transition: none;
}

.swiper-slide-thumb-active .swiper-pagination-progress-body-helper {
  transition-property: width;
  transition-timing-function: linear;
  width: 100%;
}

.swiper-pagination-progress-light .swiper-pagination-progress-body {
  background-color: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress-light .swiper-pagination-progress-body-helper {
  background-color: #fff;
}

.swiper-pagination-progressbar {
  background-color: rgba(55, 125, 255, 0.1);
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #377dff;
}

.swiper-pagination-fraction {
  display: block;
  letter-spacing: 0.125rem;
}

.swiper-pagination-fraction .swiper-pagination-current {
  font-size: 4rem;
  line-height: 4rem;
}

.swiper-thumbs {
  box-sizing: border-box;
}

.swiper-thumbs .swiper-slide {
  cursor: pointer;
  opacity: .4;
}

.swiper-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-equal-height .swiper-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.swiper-equal-height .swiper-slide {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-container-vertical > .swiper-pagination-bullets {
  position: absolute;
  width: auto !important;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin: 0;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0.25rem 0;
}

.swiper-container-horizontal > .swiper-pagination-middle-y-end {
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.swiper-center-mode-end {
  margin-right: calc(-1px - ((100vw - 100%) / 2) + 15px) !important;
}

.swiper-thumb-progress {
  width: 110%;
  height: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.swiper-thumb-progress-avatar {
  position: relative;
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  padding: 0.25rem;
  margin: 0.25rem;
  border-radius: 50%;
}

.swiper-thumb-progress-avatar-img {
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

.swiper-thumb-progress .swiper-thumb-progress-path {
  opacity: 0;
  fill: transparent;
  stroke: #377dff;
  stroke-width: 8;
  stroke-dashoffset: 477;
  stroke-dashoffset: 0px;
}

.swiper-thumb-progress .swiper-thumb-progress-path {
  opacity: 0;
  fill: transparent;
  stroke: #377dff;
  stroke-width: 8;
  stroke-dashoffset: 477;
  stroke-dashoffset: 0px;
}

@-webkit-keyframes swiperThumbProgressDash {
  from {
    stroke-dasharray: 0 477;
  }
  to {
    stroke-dasharray: 477 477;
  }
}

@keyframes swiperThumbProgressDash {
  from {
    stroke-dasharray: 0 477;
  }
  to {
    stroke-dasharray: 477 477;
  }
}

/*------------------------------------
  Leaflet
------------------------------------*/
.leaflet {
  min-height: 30rem;
  height: 100%;
  z-index: 0;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  border-radius: 0.5rem;
}

.leaflet-popup {
  margin-bottom: 2.5rem;
}

/*------------------------------------
  NoUiSlider
------------------------------------*/
.range-slider.range-slider-pips {
  min-height: 3rem;
}

.range-slider .noUi-target {
  border: none;
  box-shadow: none;
  background: #e7eaf3;
  margin-top: 1.25rem;
}

.range-slider .noUi-connect {
  background-color: #377dff;
}

.range-slider .noUi-horizontal {
  height: 0.3125rem;
}

.range-slider .noUi-horizontal .noUi-handle {
  top: -0.75rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
}

.range-slider .noUi-handle {
  border: none;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  border-radius: 50%;
}

.range-slider .noUi-handle:before, .range-slider .noUi-handle:after {
  display: none;
}

.range-slider .noUi-horizontal .noUi-tooltip {
  bottom: 130%;
}

.range-slider .noUi-tooltip {
  border: none;
  font-size: 0.8125rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(140, 152, 164, 0.25);
  min-width: 2.5rem;
  padding: 0.5rem 0.5rem;
}

.range-slider .noUi-tooltip::after {
  position: absolute;
  display: block;
  bottom: -0.625rem;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -0.3125rem;
  overflow: hidden;
  border: 0.3125rem solid transparent;
  border-top-color: #fff;
  content: '';
}

.range-slider .noUi-marker {
  width: 0.0625rem;
  background: #bdc5d1;
}

.range-slider .noUi-pips {
  color: #677788;
}

.range-slider .noUi-value-large {
  color: #677788;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
