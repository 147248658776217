//Margin 
@use "sass:math";

@for $i from 8 through 50 {
    //Top
    .mt-#{$i} {
        margin-top: #{math.div($i, 2)+0.5}#{"rem"};
    }
    .mtn-#{$i}px {
        margin-top: #{"-"}#{$i}#{"px"};
    }
    //Bottom
    .mb-#{$i} {
        margin-bottom: #{math.div($i, 2)+0.5}#{"rem"};
    }
    //Gutter 
    .gx-#{$i} {
        --bs-gutter-x: #{math.div($i, 2)+0.5}#{"rem"};
    }
    @each $media in $medias {
        @include media-breakpoint-up($media) {
            //Bottom
            .mb-#{$media}-#{$i} {
                margin-bottom: #{math.div($i, 2)+0.5}#{"rem"};
            }
            //Gutters
            .gx-#{$media}-#{$i} {
                --bs-gutter-x: #{math.div($i, 2)+0.5}#{"rem"};
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .mt-lg-n15 {
        margin-top: -7rem!important;
    }
}

@include media-breakpoint-up(md) {
    .mb-md-20 {
        margin-bottom: 10.5rem !important;
    }
}

@include media-breakpoint-up(lg) {
    .mb-lg-0 {
        margin-bottom: 0rem !important;
    }
}