//Height 
@for $i from 0 through 1200 {
    .h-#{$i} {
        height: #{$i}#{"%"};
    }
    .lh-#{$i} {
        line-height: #{$i}#{"%"};
    }
    .h-#{$i}px {
        height: #{$i}#{"px"};
    }
    .lh-#{$i}px {
        line-height: #{$i}#{"px"};
    }
}