//Button
.btn {

    @each $key,
    $value in $colors {
        &.bg-is-#{$key} {
            &:hover {
                color: $value !important;
                border-color: $value;
            }
        }
    }
}

//--Back to top
.back-to-top {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 2;
}
