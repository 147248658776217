//Images 
.content-img {
	width: 100%;
	height: 100%;
	display: table;
	border-radius: 70% 40% 70% 40%;
	img {
		border-radius: 40% 70% 40% 70%;
	}
	&.tarifs {
		border-radius: 20% 73% 20% 73%;
		img {
			border-radius: 90% 20% 90% 20%;
		}
	}
}

.img-fit-cover{
	object-fit: cover;
}