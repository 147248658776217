//Body
/* purgecss start ignore */
* {
	margin: 0;
	padding: 0;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	position: relative;
	min-height: 100%;
	&:focus {
		outline: none;
	}
}

html,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: $inter!important;
	font-weight: 400;
}

p, span, ul {
    color: $third-color;
}

// 
.typed-cursor {
    color: $primary-color;
}
////////////////////
.panel-default>.panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #e4e5e7;
    padding: 0;
    user-select: none;
}

.panel-default>.panel-heading a {
    display: block;
    padding: 10px 15px;
    &::after {
        content: "";
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        float: right;
        transition: transform .25s linear;
        -webkit-transition: -webkit-transform .25s linear;
    }
}

.panel-default>.panel-heading a[aria-expanded="true"] {
    background-color: #eee;
}

.panel-default>.panel-heading a[aria-expanded="true"]:after {
    content: "\2212";
    transform: rotate(180deg);
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
    content: "\002b";
    transform: rotate(90deg);
}

//Text-Align
.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

//Rotate 
.rotate-90 {
    transform: rotate(-90deg);
}
.rotate-45 {
    transform: rotate(45deg);
}

//Tooltip
.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}
.tooltip.show {
    opacity: 1;
}
.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
    bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #21325b;
}
.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #21325b;
}
.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
    padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
    top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #21325b;
}
.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
    padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #21325b;
}
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #21325b;
    border-radius: 0.5rem;
}
/* purgecss end ignore */
