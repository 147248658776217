//Width 
@for $i from 0 through 1200 {
    .w-#{$i} {
        width: #{$i}#{"%"};
    }
    .w-#{$i}rem {
        width: #{$i}#{"rem"};
    }
    .w-#{$i}px {
        width: #{$i}#{"px"};
    }
}
.w-fit-content {
    width: fit-content;
}

@include media-breakpoint-up(lg) {
    .w-lg-auto {
        width: auto!important;
    }
}