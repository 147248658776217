//Bandeau
.section-bandeau {
    .devices-top-start-50 {
        top: 135px;
        &::before {
            content: "";
            background-color: $seventh-color;
            border-radius: 60% 40% 30% 45%/60% 30% 70% 25%;
            width: 125%;
            height: 130%;
            position: absolute;
            top: -120px;
            opacity: 0.8;
        }
    }
    .device-tablet-img, .device-browser-img {
        transition: transform 0.3s ease-in-out;
    }
    .devices-rotated {
        transform: rotate(-7deg);
        .devices-rotated-body {
            transform: translate3d(12%, 0, 0);
        }
    }
    .device-mobile-frame {
        border-radius: 0.5rem;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        background: linear-gradient(135deg, $primary-color 0%, $primary-color 25%, $primary-color 50%, $primary-color 75%, $primary-color 100%); 
        height: 100%;
        border-radius: 18px;
        left: 1px;
        width: 100%;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
    }
    .video-player-icon {
        color: #FFF;
        background-color: $fourth-color;
        top: 35%;
        left: 44%;
    }
    .video-player {
        &:hover {
            .video-player-icon {
                background-color: #FFF;
                color: $fourth-color;
            }
            ~ .image {
                .overlay {
                    opacity: 0.12;
                }
            }
            
        }
    }
}

//Responsive 
@media (max-width: $media_991) {
    .section-bandeau {
        .heading {
            text-align: center;
        }
        .essaigratuit {
            justify-content: center;
        }
    }
}

.avoid-page-jump {
    @media (max-width: $media_1200) {
        min-height: 255px;
    }

    @media (max-width: $media_991) {
        min-height: 130px;
    }

    @media (max-width: $media_768) {
        min-height: 0;
    }
}