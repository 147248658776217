//Fil d'ariane
.breadcrumb {
    &.dashboard {
        li {
            a {
                color: white;
                transition: color .15s linear;
                &:hover {
                    color: $primary-color;
                }
            }
            &.active {
                a {
                    color: $primary-color;
                }
            }
        }
    }
    li {
        a {
            transition: color .15s linear;
        }
    }
}
