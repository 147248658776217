//Right
@for $i from 0 through 100 {
    .r-#{$i} {
        right: #{$i}#{"%"};
    }
    .r-#{$i}px {
        right: #{$i}#{"px"};
    }
    .rn-#{$i} {
        right: #{"-"}#{$i}#{"%"};
    }
    .rn-#{$i}px {
        right: #{"-"}#{$i}#{"px"};
    }
}