//Card 

//--Card video 
.card-video {
    .content-video {
        .overlay {
            position: absolute;
            bottom: 0;
            background: linear-gradient(135deg, $primary-color 0%, $primary-color 25%, $primary-color 50%, $primary-color 75%, $primary-color 100%);
            height: 100%;
            border-radius: 18px;
            left: 1px;
            width: 100%;
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
        }

        .video-player-icon {
            color: #FFF;
            background-color: $fourth-color;
            width: 3rem;
            height: 3rem;
        }

        a {
            position: relative;
            height: 100%;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            &:hover {
                .video-player-icon {
                    background-color: #FFF;
                    color: $fourth-color;
                }

                .image {
                    .overlay {
                        opacity: 0.12;
                    }
                }
            }
        }

    }
}

//--Card with buttons 
.card {
    &.with-button {
        &:hover {
            // max-height technique
            .accordion-content {
                // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.
                max-height: 300px;
                transform: scaleY(1);
            }
    
        }
        .accordion-content {
            /*transition: max-height 1s;
            position: absolute;
            background: $primary-color;
            color: #FFF;
            overflow: hidden;
            // "height: 0" not work with css transitions
            max-height: 0;*/
            position: absolute;
            background: $primary-color;
            bottom: -45px;
            border-radius: 0.5rem;
            //
            transform-origin: 0 0%;
            width: 100%;
            //height: 300px;
            transition: transform .25s;
            transform: scaleY(0);
            &:hover {
                background: $third-color;
            }
        }
        
        .accordion-inner {
            padding: 0 15px;
        }
        
        .accordion-toggle {
            background: $primary-color;
            display: block;
            font-size: 30px;
            margin: 0 0 10px;
            padding: 10px;
            text-align: center;
            text-decoration: none;
        
        }
    }
}