// Body
$body-bg: #f8fafc;

// Typography
$inter: 'Inter', sans-serif;

//Colors
$primary-color: #3699FF;
$secondary-color: #F5CA99;
$third-color: #242939;
$fourth-color: #377DFF;
$fifth-color: #9FC0DA;
$sixth-color: #C9A7CF;
$seventh-color: #F2F5FA;
$eighth-color: #FAF6FB;
$nineth-color: #A0C0DA;


$colors: (primary: $primary-color, secondary: $secondary-color, third: $third-color, fourth: $fourth-color, fifth: $fifth-color,
        sixth: $sixth-color, seventh: $seventh-color, eighth: $eighth-color, nineth: $nineth-color);

//--Bootstrap Icons 
$bootstrapicons: 'bootstrap-icons';

//Responsive
$medias: ("xs", "sm", "md", "lg", "xl", "xxl");

$media_1200: 1200px;
$media_1024: 1024px;
$media_991: 991px;
$media_768: 768px;
$media_575: 575px;
$media_400: 400px;
