//Header
header {
    #hide {
        display: none;
    }
    &.navbar {
        padding: 1.5rem 0;

        .nav-item {
            &:hover {
                >.nav-link {
                    color: $primary-color !important;
                }
            }

            >.nav-link {
                @include media-breakpoint-between(lg, xl) {
                    font-size: 14px;
                }
                &.active {
                    color: $primary-color !important;
                    font-weight: bold;
                }
            }
            
        }

        .media {
            display: flex;
            align-items: flex-start;

            .media-body {
                flex: 1;
            }
        }

        &.navbar-dark {
            background-color: $third-color !important;

            .navbar-absolute-top-scroller{
                background-color: $third-color;

                .navbar-nav{
                    background-color: $third-color;
                }
            }

            .dropdown-dark{
                background: $third-color !important;
            
                .dropdown-item{
                    color: white;
            
                    &:hover{
                        color: $primary-color;
                        background-color: rgba(255, 255, 255, 0.05);
                    }
                }
            }
        }

        &.navbar-scrolled {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}

/* purgecss start ignore */
.hs-menu-initialized {
    position: relative;
    z-index: 10;
}

.hs-menu-initialized .animated {
    animation-duration: 300ms;
}

.hs-overflow-x-locked {
    overflow-x: hidden;
}

.hs-sub-menu,
.hs-mega-menu {
    display: none;
    background-color: #fff;
}

//Responsive
@media (min-width: 576px) {

    .hs-mega-menu-desktop-sm,
    .hs-sub-menu-desktop-sm {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 2;
        margin-top: -.002rem;
    }
}

@media (min-width: 768px) {

    .hs-mega-menu-desktop-md,
    .hs-sub-menu-desktop-md {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 2;
        margin-top: -.002rem;
    }
}

@media (min-width: 992px) {

    .hs-mega-menu-desktop-lg,
    .hs-sub-menu-desktop-lg {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 2;
        margin-top: -.002rem;
    }
    .navbar-expand-lg {
        .navbar-nav {
            .nav-link {
                padding: 1.5rem .5rem;
            }
        }
    }
}

@media (min-width: 1200px) {

    .hs-mega-menu-desktop-xl,
    .hs-sub-menu-desktop-xl {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 2;
        margin-top: -.002rem;
    }
}

.hs-sub-menu {
    min-width: 180px;
}

.hs-has-sub-menu {
    overflow: hidden;
    position: relative;
}

.hs-sub-menu-opened {
    overflow: visible;
}

.hs-sub-menu .hs-sub-menu,
.hs-mega-menu .hs-sub-menu,
.hs-sub-menu .hs-mega-menu,
.hs-mega-menu .hs-mega-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
}

/*------------------------------------
    Vertical Direction
------------------------------------*/
.hs-menu-vertical .hs-sub-menu,
.hs-menu-vertical .hs-mega-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
}

.hs-menu-vertical .hs-sub-menu {
    width: auto;
}

.hs-menu-vertical .hs-mega-menu {
    height: 100%;
}

/*------------------------------------
    Mobile state
------------------------------------*/
.hs-mobile-state .hs-sub-menu,
.hs-mobile-state .hs-mega-menu {
    position: static;
    visibility: visible;
}

.hs-mobile-state .hs-has-mega-menu[data-max-width]>.hs-mega-menu,
.hs-mobile-state .hs-has-sub-menu[data-max-width]>.hs-sub-menu {
    max-width: initial !important;
}

/*------------------------------------
    RTL
------------------------------------*/
.hs-menu-initialized.hs-rtl {
    direction: rtl;
    unicode-bidi: embed;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu {
    left: auto;
    right: 0;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-mega-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-mega-menu {
    left: auto;
    right: 100%;
}

/*------------------------------------
    Smart Position
------------------------------------*/
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
    left: auto;
    right: 0;
}

.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
    left: auto;
    right: 100%;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
    right: auto;
    left: 0;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
    right: auto;
    left: 100%;
}

/*------------------------------------
    Positions
    (only 'horizontal' direction)
------------------------------------*/
.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-left {
    left: 0;
    right: auto;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-right {
    left: auto;
    right: 0;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-center {
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
    margin: auto;
}

/* purgecss end ignore */
