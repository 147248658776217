//List
/*.list-no-checked-danger {
    .list-checked-item {
        &::before {
            background-image: none;
            content: "\f659";
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            color: red;
        }
    }
}*/
/*ul {
    &.square-is-primary {
        list-style: square;
        li {
            &::marker {
                color: $primary-color;
            }
        }
    }
}*/
.text-end {
    ul {
        list-style-position: inside;
    }
}