//Colors
@use "sass:math";

@each $key,
$value in $colors {

    //Colors
    .is-#{$key} {
        color: $value;
    }
    .bg-is-#{$key} {
        background-color: $value;
    }
    @for $i from 0 through 100 {
        .bg-is-#{$key}-#{$i} {
            background-color: rgba($value, math.div($i, 100));
        }
    }
    .border-is-#{$key} {
        border-color: $value;
    }
    //Typing word
    .is-#{$key} {
        &.typing {
            .typed-cursor {
                color: $value;
            }
        }        
    }
}

//Filters
.filter-is-primary {
    filter: brightness(0) saturate(100%) invert(45%) sepia(69%) saturate(1216%) hue-rotate(191deg) brightness(103%) contrast(101%);
}
.filter-is-secondary {
    filter: brightness(0) saturate(100%) invert(79%) sepia(90%) saturate(272%) hue-rotate(316deg) brightness(96%) contrast(99%);
}

//Gradient
.bg-is-sixth-nineth {
    background: linear-gradient(to right, rgba($sixth-color, 1), rgba($nineth-color, 1));
}