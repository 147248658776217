//Police

//Bootstrap Icons
$bootstrap-icons-font: "bootstrap-icons" !default;
$bootstrap-icons-font-src: url("/fonts/bootstrap-icons/bootstrap-icons.woff2") format("woff2"),
url("/fonts/bootstrap-icons/bootstrap-icons.woff") format("woff") !default;

@font-face {
    font-family: $bootstrap-icons-font;
    src: $bootstrap-icons-font-src;
    font-display: swap;
}